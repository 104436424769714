.l-event-agenda {
  overflow: hidden;
}

.l-event-agenda__title {
  text-align: center;
}

.l-event-agenda__subtitle {
  color: $color-gray-medium;
  margin-top: 0.5rem;
}

.l-event-agenda__date {
  color: $color-blue;
  text-align: center;
  margin-top: 4rem;
}

.l-event-agenda__list {
  margin-top: 2rem;
  max-width: rem(1000);
  margin-left: auto;
  margin-right: auto;
}
