@charset "UTF-8";
@font-face {
  font-family: "GT Walsheim Web";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fnt/gt-walsheim-pro-regular.woff2") format("woff2"), url("../fnt/gt-walsheim-pro-regular.woff") format("woff");
}

@font-face {
  font-family: "GT Walsheim Web";
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url("../fnt/gt-walsheim-pro-regular-oblique.woff2") format("woff2"), url("../fnt/gt-walsheim-pro-regular-oblique.woff") format("woff");
}

@font-face {
  font-family: "GT Walsheim Web";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("../fnt/gt-walsheim-pro-bold.woff2") format("woff2"), url("../fnt/gt-walsheim-pro-bold.woff") format("woff");
}

@font-face {
  font-family: "GT Walsheim Web";
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url("../fnt/gt-walsheim-pro-bold-oblique.woff2") format("woff2"), url("../fnt/gt-walsheim-pro-bold-oblique.woff") format("woff");
}

html {
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

main {
  display: block;
}

b,
strong {
  font-weight: bolder;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
}

ul,
ol,
p {
  margin: 0;
  padding: 0;
}

ul li {
  padding: 0;
  list-style-type: none;
}

blockquote,
figure {
  margin: 0;
}

figcaption {
  font-weight: inherit;
}

img,
video {
  width: 100%;
  height: auto;
  vertical-align: top;
}

video {
  outline: 0;
}

iframe {
  border: none;
}

address {
  font-style: normal;
}

svg {
  vertical-align: top;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: 0;
  cursor: pointer;
  color: inherit;
  background: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
}

input,
select {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-transform: none;
  appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

label {
  display: block;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: block;
  width: 100%;
  padding: 0;
  white-space: normal;
}

.js-focus-visible a:focus:not(.focus-visible), .js-focus-visible
button:focus:not(.focus-visible) {
  outline: none;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
}

::selection {
  background-color: #313130;
  color: white;
}

:focus {
  outline: 3px solid #0090e1;
  outline-offset: 1px;
}

html {
  background-color: #f3f3f0;
  color: #313130;
  font-family: "GT Walsheim Web", -system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1.6;
  letter-spacing: 0.02em;
  font-variant: no-common-ligatures;
}

@media (max-width: 22.4375em) {
  html {
    hyphens: auto;
  }
}

body {
  min-height: 100vh;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 100rem;
  box-shadow: 0 0 50px rgba(49, 49, 48, 0.05);
  font-size: 1.0625rem;
}

@media (min-width: 30em) {
  body {
    font-size: calc(0.27778vw + 0.97917rem);
  }
}

@media (min-width: 75em) {
  body {
    font-size: 1.1875rem;
  }
}

main {
  flex-grow: 1;
}

.u-bg--white {
  background-color: white;
}

.u-bg--green {
  background-color: #00cb76;
}

.u-mw--article {
  max-width: 42.5rem;
}

.u-o--hidden {
  overflow: hidden;
}

.u-ta--center {
  text-align: center;
}

.u-px--base {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 30em) {
  .u-px--base {
    padding-left: 2.5rem;
  }
}

@media (min-width: 75em) {
  .u-px--base {
    padding-left: 4.25rem;
  }
}

@media (min-width: 30em) {
  .u-px--base {
    padding-right: 2.5rem;
  }
}

@media (min-width: 75em) {
  .u-px--base {
    padding-right: 4.25rem;
  }
}

.u-pt--base {
  padding-top: 3.5rem;
}

@media (min-width: 45em) {
  .u-pt--base {
    padding-top: 5rem;
  }
}

.u-pb--base {
  padding-bottom: 6rem;
}

@media (min-width: 45em) {
  .u-pb--base {
    padding-bottom: 7.5rem;
  }
}

.u-pt--xs {
  padding-top: 1rem;
}

@media (min-width: 45em) {
  .u-pt--xs {
    padding-top: 1.5rem;
  }
}

.u-pt--s {
  padding-top: 1.5rem;
}

@media (min-width: 45em) {
  .u-pt--s {
    padding-top: 2.5rem;
  }
}

.u-pt--ms {
  padding-top: 2.5rem;
}

@media (min-width: 45em) {
  .u-pt--ms {
    padding-top: 3.5rem;
  }
}

.u-pt--m {
  padding-top: 3.5rem;
}

@media (min-width: 45em) {
  .u-pt--m {
    padding-top: 5rem;
  }
}

.u-pt--ml {
  padding-top: 4rem;
}

@media (min-width: 45em) {
  .u-pt--ml {
    padding-top: 6rem;
  }
}

.u-pt--l {
  padding-top: 5rem;
}

@media (min-width: 45em) {
  .u-pt--l {
    padding-top: 7rem;
  }
}

.u-pt--xl {
  padding-top: 6rem;
}

@media (min-width: 45em) {
  .u-pt--xl {
    padding-top: 8rem;
  }
}

.u-pb--xs {
  padding-bottom: 1rem;
}

@media (min-width: 45em) {
  .u-pb--xs {
    padding-bottom: 1.5rem;
  }
}

.u-pb--s {
  padding-bottom: 1.5rem;
}

@media (min-width: 45em) {
  .u-pb--s {
    padding-bottom: 2.5rem;
  }
}

.u-pb--ms {
  padding-bottom: 2.5rem;
}

@media (min-width: 45em) {
  .u-pb--ms {
    padding-bottom: 3.5rem;
  }
}

.u-pb--m {
  padding-bottom: 3.5rem;
}

@media (min-width: 45em) {
  .u-pb--m {
    padding-bottom: 5rem;
  }
}

.u-pb--ml {
  padding-bottom: 4rem;
}

@media (min-width: 45em) {
  .u-pb--ml {
    padding-bottom: 6rem;
  }
}

.u-pb--l {
  padding-bottom: 5rem;
}

@media (min-width: 45em) {
  .u-pb--l {
    padding-bottom: 7rem;
  }
}

.u-pb--xl {
  padding-bottom: 6rem;
}

@media (min-width: 45em) {
  .u-pb--xl {
    padding-bottom: 8rem;
  }
}

.u-text-spacing > * + * {
  margin-top: 1.5em;
}

.u-text-spacing > .o-hl--1 + * {
  margin-top: 2em;
}

.u-text-spacing > .o-hl--2 + * {
  margin-top: 1.5em;
}

.u-text-spacing > .o-hl--3 + * {
  margin-top: 0.5em;
}

.u-text-spacing > * + .o-hl--1 {
  margin-top: 4.5rem;
}

.u-text-spacing > * + .o-hl--2 {
  margin-top: 4.5rem;
}

.u-text-spacing > * + .o-hl--3 {
  margin-top: 3rem;
}

.u-text-spacing > .o-hl--1 + .o-hl--2 {
  margin-top: 3rem;
}

.u-text-spacing > .o-hl--2 + .o-hl--3 {
  margin-top: 2.5rem;
}

.u-text-spacing > .o-service-icon + .o-hl--2 {
  margin-top: 3.5rem;
}

.u-text-spacing > .o-quote + * {
  margin-top: 3.5rem;
}

.u-text-spacing > .o-figure + * {
  margin-top: 3.5rem;
}

.u-text-spacing > .c-text-teaser + * {
  margin-top: 3rem;
}

.u-text-spacing > * + .o-quote {
  margin-top: 3.5rem;
}

.u-text-spacing > * + .o-figure {
  margin-top: 3.5rem;
}

.u-text-spacing > * + .c-text-teaser {
  margin-top: 3rem;
}

.u-text-spacing > p > .o-button {
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}

.u-text-spacing .o-figure--small {
  max-width: 15.625rem;
  margin-left: auto;
  margin-right: auto;
}

.u-teaser-spacing > * + * {
  margin-top: 0.875rem;
}

.u-teaser-spacing > * + .o-hl--1 {
  margin-top: 1.375rem;
}

.u-teaser-spacing > * + .o-button {
  margin-top: 1.75rem;
}

.u-teaser-spacing > .o-category + .o-hl--1 {
  margin-top: 1rem;
}

.u-teaser-spacing > .o-category + .o-hl--2 {
  margin-top: 0.625rem;
}

.u-teaser-spacing > .o-service-icon + .o-hl--3 {
  margin-top: 2.25rem;
}

@media (min-width: 45em) {
  .u-teaser-spacing > .o-service-icon + .o-hl--3 {
    margin-top: 3.5rem;
  }
}

.u-teaser-spacing > .o-hl--1 + * {
  margin-top: 1rem;
}

.u-teaser-spacing > .o-hl--3 + * {
  margin-top: 0;
}

.u-anim-hidden {
  opacity: 0;
}

.no-js .u-anim-hidden {
  opacity: 1;
}

.u-a11y-hidden, .o-skip-link {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes move-in {
  from {
    transform: translateY(32px);
  }
  to {
    transform: translateY(0);
  }
}

@media (min-width: 45em) {
  @keyframes move-in {
    from {
      transform: translateY(48px);
    }
    to {
      transform: translateY(0);
    }
  }
}

.u-anim--in,
.u-anim--in-stagger > * {
  animation: fade-in 1000ms cubic-bezier(0, 0, 0.25, 1) both, move-in 1500ms cubic-bezier(0.23, 1, 0.32, 1) both;
  animation-play-state: paused;
}

.no-js .u-anim--in, .no-js
.u-anim--in-stagger > * {
  animation-play-state: running;
}

.u-anim--delay-1 {
  animation-delay: 150ms;
}

.u-anim--in-stagger > *:nth-child(1) {
  animation-delay: 150ms;
}

.u-anim--delay-2 {
  animation-delay: 300ms;
}

.u-anim--in-stagger > *:nth-child(2) {
  animation-delay: 300ms;
}

.u-anim--delay-3 {
  animation-delay: 450ms;
}

.u-anim--in-stagger > *:nth-child(3) {
  animation-delay: 450ms;
}

.u-anim--delay-4 {
  animation-delay: 600ms;
}

.u-anim--in-stagger > *:nth-child(4) {
  animation-delay: 600ms;
}

.u-anim--delay-5 {
  animation-delay: 750ms;
}

.u-anim--in-stagger > *:nth-child(5) {
  animation-delay: 750ms;
}

.u-anim--delay-6 {
  animation-delay: 900ms;
}

.u-anim--in-stagger > *:nth-child(6) {
  animation-delay: 900ms;
}

.u-anim--delay-7 {
  animation-delay: 1050ms;
}

.u-anim--in-stagger > *:nth-child(7) {
  animation-delay: 1050ms;
}

.u-anim--play,
.u-anim--in-stagger.u-anim--play > * {
  animation-play-state: running;
}

.o-link {
  position: relative;
  display: inline-block;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.o-link--extended::after {
  content: '';
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  top: -0.375rem;
  left: 0;
  padding: 0.375rem 0;
}

.o-link:hover, .o-link:focus, .o-link--active {
  color: #00cb76;
}

.o-link--white {
  color: white;
}

.o-link--white:hover, .o-link--white:focus, .o-link--white.o-link--active {
  color: #313130;
}

.o-link--green {
  color: #00cb76;
}

.o-link--green:hover, .o-link--green:focus, .o-link--green.o-link--active {
  color: #313130;
}

@media (max-width: 59.9375em) {
  .o-link--sub-active {
    color: inherit;
  }
}

.o-link:active {
  transform: scale(0.95);
}

.o-link--more {
  text-decoration: underline;
  color: #00cb76;
}

.o-link--more:hover, .o-link--more:focus {
  color: #313130;
}

.o-link--more::after {
  content: '';
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  top: -0.375rem;
  left: 0;
  padding: 0.375rem 0;
}

.o-link__icon {
  width: 0.75em;
  display: inline-block;
}

.o-link__icon:last-child {
  text-align: right;
}

.o-link__icon--svg {
  width: 1.0625em;
  height: 1.0625em;
  position: relative;
  top: 0.1875em;
  margin-right: 0.5em;
}

.o-link__icon--svg svg {
  width: 100%;
  height: 100%;
  display: block;
  fill: #a8a8a8;
}

.o-skip-link:focus {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: auto;
  clip: auto;
  padding: 0.625rem 1.25rem;
  z-index: 10;
  background-color: #f3f3f0;
  text-decoration: none;
}

.o-text-link {
  text-decoration: underline;
  text-decoration-color: #8c8c8c;
  transition: color 150ms cubic-bezier(0, 0, 0.25, 1), text-decoration-color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.o-text-link:hover, .o-text-link:focus {
  color: #00cb76;
  text-decoration-color: #00cb76;
}

.o-text-link:active {
  color: currentColor;
  text-decoration-color: currentColor;
}

.o-nav-icon {
  width: 100%;
  height: auto;
  display: block;
  fill: none;
  pointer-events: none;
  stroke-width: 2.5;
  stroke: currentColor;
}

.o-nav-icon__line {
  stroke-dasharray: 24;
  stroke-dashoffset: 0;
  opacity: 1;
  transition: stroke-dashoffset 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 100ms cubic-bezier(0, 0, 0.25, 1);
  transition-delay: 200ms, 200ms;
}

.o-nav-icon__line:nth-child(2) {
  transition-delay: 300ms, 300ms;
}

.o-nav-icon__line:nth-child(3) {
  transition-delay: 400ms, 400ms;
}

.o-nav-icon--active .o-nav-icon__line {
  stroke-dashoffset: 24;
  opacity: 0;
  transition-delay: 0ms, 200ms;
}

.o-nav-icon--active .o-nav-icon__line:nth-child(1) {
  transition-delay: 200ms, 400ms;
}

.o-nav-icon--active .o-nav-icon__line:nth-child(2) {
  transition-delay: 100ms, 300ms;
}

.o-nav-icon__cross {
  stroke-dasharray: 33.941123962402344;
  stroke-dashoffset: 33.941123962402344;
  opacity: 0;
  transition: stroke-dashoffset 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 100ms cubic-bezier(0, 0, 0.25, 1);
  transition-delay: 0ms, 200ms;
}

.o-nav-icon__cross:nth-child(5) {
  transition-delay: 100ms, 300ms;
}

.o-nav-icon--active .o-nav-icon__cross {
  stroke-dashoffset: 0;
  opacity: 1;
  transition-delay: 300ms, 300ms;
}

.o-nav-icon--active .o-nav-icon__cross:nth-child(5) {
  transition-delay: 400ms, 400ms;
}

.o-quicknav-button {
  padding: 0.25em 0.5em;
  display: inline-flex;
  align-items: center;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.o-quicknav-button > * + * {
  margin-left: 0.5em;
}

.o-quicknav-button:hover, .o-quicknav-button:focus {
  color: #00cb76;
}

.o-quicknav-button:active {
  transform: scale(0.95);
}

.o-quicknav-button svg {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.5;
  display: inline-block;
  width: 1.125em;
  height: 1.125em;
}

.o-button {
  display: inline-block;
  position: relative;
  background-color: #00cb76;
  color: white;
  padding: 0.3125em 1.5em 0.3125em;
  border-radius: 1px;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), background-color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.o-button:not(.o-button--loading):hover, .o-button:not(.o-button--loading):focus {
  background-color: #313130;
}

.o-button:not(.o-button--loading):active {
  transform: scale(0.95);
}

.o-button[disabled] {
  cursor: not-allowed;
  background-color: #a8a8a8;
}

.o-button[disabled]:hover, .o-button[disabled]:focus, .o-button[disabled]:active {
  background-color: #a8a8a8;
  transform: none;
}

.o-button__text {
  display: block;
  transition: transform 300ms cubic-bezier(0, 0, 0.25, 1), opacity 200ms cubic-bezier(0, 0, 0.25, 1);
}

.o-button--loading .o-button__text {
  opacity: 0;
  transform: scale(0.75);
}

@keyframes button-spinner-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.o-button__spinner {
  width: 1.125em;
  height: 1.125em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5625em;
  margin-left: -0.5625em;
  opacity: 0;
  transform: scale(0.25);
  transition: transform 300ms cubic-bezier(0, 0, 0.25, 1), opacity 200ms cubic-bezier(0, 0, 0.25, 1);
}

.o-button__spinner::after {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  border-top: 2px solid rgba(255, 255, 255, 0);
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-radius: 50%;
  transform-origin: center center;
}

.o-button--loading .o-button__spinner {
  opacity: 1;
  transform: scale(1);
}

.o-button--loading .o-button__spinner::after {
  animation: button-spinner-anim 1s linear infinite;
}

.o-input--hnpt {
  position: absolute;
  left: -9999px;
}

.o-input__label {
  margin-bottom: 0.125em;
  font-size: 1rem;
}

@media (min-width: 30em) {
  .o-input__label {
    font-size: calc(0.13889vw + 0.95833rem);
  }
}

@media (min-width: 75em) {
  .o-input__label {
    font-size: 1.0625rem;
  }
}

.o-input__input,
.o-input__select {
  padding: 0.4375rem 0.8125rem 0.375rem;
  border: 1px solid rgba(168, 168, 168, 0.5);
  color: #313130;
  background-color: white;
  outline-offset: -2px;
  transition: outline 150ms cubic-bezier(0, 0, 0.25, 1);
  outline: 2px solid transparent;
}

.o-input--light .o-input__input, .o-input--light
.o-input__select {
  background-color: #fafaf9;
}

.o-input__input:focus,
.o-input__select:focus {
  outline-color: #00cb76;
}

.o-input__input::placeholder {
  color: #a8a8a8;
  opacity: 1;
}

.o-input__input[type='search']::-webkit-search-decoration {
  appearance: none;
}

.o-input__select {
  background-repeat: no-repeat;
  background-position: right 0.875em center;
  background-size: 1.25em;
  background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 13'%3E%3Cpolyline fill='none' stroke='rgb(168, 168, 168)' stroke-width='2.5' points='1,1 12,11.5 23,1'/%3E%3C/svg%3E");
}

.o-input__select:invalid {
  color: #a8a8a8;
}

.o-input__select option {
  color: #313130;
}

.o-input__select::-ms-expand {
  display: none;
}

.o-input__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #313130;
}

.o-input__suggestions {
  display: none;
}

.o-checkbox {
  position: relative;
}

.o-checkbox__input {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  left: 0;
  top: 0.125em;
  opacity: 0;
  pointer-events: none;
}

.o-checkbox__label {
  display: inline-flex;
  align-items: flex-start;
  user-select: none;
  font-size: 1rem;
}

@media (min-width: 30em) {
  .o-checkbox__label {
    font-size: calc(0.13889vw + 0.95833rem);
  }
}

@media (min-width: 75em) {
  .o-checkbox__label {
    font-size: 1.0625rem;
  }
}

.o-checkbox__label::before {
  content: '';
  display: inline-block;
  flex-shrink: 0;
  width: 1.3125em;
  height: 1.25em;
  position: relative;
  top: 0.0625em;
  margin-right: 0.625em;
  background-color: white;
  border: 1px solid rgba(168, 168, 168, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 77.5%;
  outline-offset: -2px;
  outline: 2px solid transparent;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), outline 150ms cubic-bezier(0, 0, 0.25, 1);
}

.o-checkbox--light .o-checkbox__label::before {
  background-color: #fafaf9;
}

.o-checkbox__input:focus + .o-checkbox__label::before {
  outline-color: #00cb76;
}

.o-checkbox__input:active + .o-checkbox__label::before {
  transform: scale(0.8);
}

.o-checkbox__input:checked:active + .o-checkbox__label::before {
  transform: none;
  transition: none;
}

.o-checkbox__input:checked + .o-checkbox__label::before {
  background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='rgb(49, 49, 48)' stroke-width='4' d='M1.7 11.5l7.6 7.7L22.6 4.8'/%3E%3C/svg%3E");
}

.o-radio {
  position: relative;
}

.o-radio__input {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  left: 0;
  top: 0.125em;
  opacity: 0;
  pointer-events: none;
}

.o-radio__label {
  user-select: none;
  border: 2px solid #a8a8a8;
  padding: 0.1875em 1.5em 0.1875em;
  border-radius: 1px;
  display: block;
  text-align: center;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), background-color 150ms ease-out, color 150ms ease-out, border-color 150ms ease-out;
}

.o-radio__label:hover, .o-radio__label:focus {
  background-color: #0090e1;
  border-color: #0090e1;
  color: white;
  cursor: pointer;
}

.o-radio__label:active {
  transform: scale(0.95);
}

.o-radio__input:checked + .o-radio__label {
  background-color: #0090e1;
  border-color: #0090e1;
  color: white;
}

.o-fieldset__legend {
  width: 100%;
  font-size: 1rem;
}

@media (min-width: 30em) {
  .o-fieldset__legend {
    font-size: calc(0.13889vw + 0.95833rem);
  }
}

@media (min-width: 75em) {
  .o-fieldset__legend {
    font-size: 1.0625rem;
  }
}

.o-fieldset__checkbox-list {
  margin-top: 1em;
  display: flex;
}

.o-fieldset__checkbox-list > * + * {
  margin-left: 1.5em;
}

.o-fieldset__radio-list {
  margin-top: 0.5em;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 45em) {
  .o-fieldset__radio-list {
    width: 48.5%;
  }
}

.o-fieldset__radio-list > * {
  width: 48%;
}

.o-fieldset__alert {
  margin-top: 0.75em;
  color: #de5959;
  position: relative;
  padding-left: 1.625em;
  display: none;
  font-size: 1rem;
}

@media (min-width: 30em) {
  .o-fieldset__alert {
    font-size: calc(0.13889vw + 0.95833rem);
  }
}

@media (min-width: 75em) {
  .o-fieldset__alert {
    font-size: 1.0625rem;
  }
}

.o-fieldset__alert--visible {
  display: block;
}

.o-fieldset__alert span {
  background-color: #de5959;
  position: absolute;
  left: 0;
  top: 0.1875em;
  width: 1.125em;
  height: 1.125em;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  line-height: 1.25em;
  font-size: 1em;
}

.o-category {
  font-size: 0.9375rem;
}

.o-category--gray {
  color: #8c8c8c;
}

.o-category--green {
  color: #00cb76;
}

@media (min-width: 30em) {
  .o-category {
    font-size: calc(0.27778vw + 0.85417rem);
  }
}

@media (min-width: 75em) {
  .o-category {
    font-size: 1.0625rem;
  }
}

.o-category__separator {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin-left: 0.25em;
  margin-right: 0.3125em;
  vertical-align: middle;
  margin-bottom: 0.125em;
  background-color: currentColor;
}

.o-hl--1 {
  font-weight: bold;
  line-height: 1.3;
  font-size: 1.75rem;
}

@media (min-width: 30em) {
  .o-hl--1 {
    font-size: calc(1.11111vw + 1.41667rem);
  }
}

@media (min-width: 75em) {
  .o-hl--1 {
    font-size: 2.25rem;
  }
}

.o-hl--2 {
  font-weight: bold;
  line-height: 1.4;
  font-size: 1.375rem;
}

@media (min-width: 30em) {
  .o-hl--2 {
    font-size: calc(0.83333vw + 1.125rem);
  }
}

@media (min-width: 75em) {
  .o-hl--2 {
    font-size: 1.75rem;
  }
}

.o-hl--2--light {
  font-weight: normal;
}

.o-hl--3 {
  font-weight: bold;
}

.o-list {
  padding-left: 1em;
}

.o-list li {
  position: relative;
}

.o-list li + li {
  margin-top: 0.25em;
}

.o-list li::before {
  content: '–';
  position: absolute;
  left: -1em;
}

.o-list--ordered {
  padding-left: 1.25em;
  list-style-type: none;
  counter-reset: section;
}

.o-list--ordered li::before {
  left: -1.25em;
  counter-increment: section;
  content: counter(section) ".";
}

.o-quote {
  quotes: "»" "«";
}

.o-quote > p::before {
  content: open-quote;
}

.o-quote > p::after {
  content: close-quote;
}

.o-quote > p {
  line-height: 1.4;
  color: #00cb76;
  font-size: 1.1875rem;
}

@media (min-width: 30em) {
  .o-quote > p {
    font-size: calc(0.41667vw + 1.0625rem);
  }
}

@media (min-width: 75em) {
  .o-quote > p {
    font-size: 1.375rem;
  }
}

.o-quote--large > p {
  line-height: 1.3;
  font-size: 1.375rem;
}

@media (min-width: 30em) {
  .o-quote--large > p {
    font-size: calc(1.94444vw + 0.79167rem);
  }
}

@media (min-width: 75em) {
  .o-quote--large > p {
    font-size: 2.25rem;
  }
}

.o-quote__footer {
  padding-top: 1.5rem;
  color: #313130;
}

.o-figure {
  width: 100%;
  outline: 1px solid transparent;
}

.o-figure__wrapper {
  height: 0;
  overflow: hidden;
  position: relative;
}

@media (min-width: 45em) {
  .o-figure__wrapper--small {
    display: none;
  }
}

@media (max-width: 44.9375em) {
  .o-figure__wrapper--large {
    display: none;
  }
}

.o-figure__wrapper::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(168, 168, 168, 0.15);
  transition: opacity 400ms cubic-bezier(0, 0, 0.25, 1);
  z-index: 0;
}

.o-figure__wrapper--transparent::before {
  opacity: 0;
}

.o-figure__wrapper > * {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.o-figure__caption {
  padding-top: 0.625em;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0ms;
  transform-origin: top;
  opacity: 1;
  backface-visibility: hidden;
}

.no-js .lazyload {
  display: none;
}

.video-lazyload,
.video-lazyloading {
  opacity: 0;
}

.video-lazyloaded {
  transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1);
  opacity: 1;
}

.o-tag {
  display: inline-block;
  background-color: rgba(168, 168, 168, 0.3);
  padding: 0.1875em 0.6875em 0.125em;
  color: #313130;
  border-radius: 1px;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), color 150ms cubic-bezier(0, 0, 0.25, 1), background-color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.o-tag:hover, .o-tag:focus {
  color: white;
  background-color: #00cb76;
}

.o-tag:active {
  transform: scale(0.95);
}

.o-map {
  width: 100%;
  height: 18.75rem;
}

.o-map__wrapper {
  height: 100%;
  background-color: #f3f3f0;
}

.o-map__marker {
  width: 34px;
  height: 52px;
  display: none;
}

.o-map__marker[style] {
  display: block;
}

.o-marker-icon {
  width: 100%;
  height: 100%;
  display: block;
}

.o-marker-icon path {
  fill: #00cb76;
}

.o-marker-icon--blue path {
  fill: #0090e1;
}

.o-marker-icon circle {
  fill: white;
}

.o-marker-icon ellipse {
  fill: #313130;
  opacity: 0.2;
}

.awesomplete [hidden] {
  display: none;
}

.awesomplete .visually-hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.awesomplete {
  position: relative;
}

.awesomplete > ul {
  position: absolute;
  left: 0;
  z-index: 1;
  min-width: 100%;
  box-sizing: border-box;
  list-style: none;
  padding: 0.375em 0;
  margin-top: 0;
  background: white;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.15);
  text-shadow: none;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 150ms cubic-bezier(0, 0, 0.25, 1);
  transform-origin: top;
}

.awesomplete > ul:empty {
  display: none;
}

.awesomplete > ul[hidden],
.awesomplete > ul:empty {
  opacity: 0;
  transform: translateY(-0.25em);
  display: block;
  transition-timing-function: ease;
}

.awesomplete > ul:before {
  content: '';
  position: absolute;
  top: -0.5em;
  left: 1.5em;
  width: 0;
  height: 0;
  padding: 0.5em;
  background: white;
  border: inherit;
  border-right: 0;
  border-bottom: 0;
  transform: rotate(45deg);
}

.awesomplete > ul > li {
  position: relative;
  padding: 0.375em 1em;
  cursor: pointer;
  line-height: 1.3;
}

.awesomplete > ul > li:hover {
  text-decoration: underline;
  color: #00cb76;
}

.awesomplete > ul > li[aria-selected='true'] {
  color: #00cb76;
}

.awesomplete mark {
  color: #00cb76;
  background-color: transparent;
}

.awesomplete li[aria-selected='true'] {
  text-decoration: underline;
}

.o-service-icon {
  display: block;
  width: 8.4375rem;
  height: 8.4375rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.o-citation {
  position: relative;
}

.o-citation__toggle {
  position: relative;
}

.o-citation__toggle:hover,
.o-citation--active .o-citation__toggle {
  color: #00cb76;
}

.o-citation__toggle::after {
  content: '';
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  top: -0.25rem;
  left: -0.75rem;
  padding: 0.25rem 0.75rem;
}

.o-citation__popover {
  text-align: center;
  font-size: 90%;
  color: #8c8c8c;
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 5px rgba(49, 49, 48, 0.2);
  z-index: -2;
  visibility: hidden;
  transform: translateY(-2rem);
  width: 0;
  height: 0;
  left: 0;
  opacity: 0;
  --offset: 0px;
}

.o-citation--active .o-citation__popover {
  padding: 0.875em 1.75em 0.8125em;
  width: 18.75rem;
  height: auto;
  z-index: 2;
  visibility: visible;
  transform: translateY(-1rem);
  opacity: 1;
  transition-duration: 0ms, 0ms, 0ms;
  transition: opacity 200ms cubic-bezier(0, 0, 0.25, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), visibility 0ms linear;
}

.js-focus-visible .o-citation__popover:focus:not(.focus-visible) {
  outline: none;
}

@supports (clip-path: polygon(0 0)) {
  .o-citation__popover::after {
    content: '';
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: -0.625rem;
    margin-left: calc(-0.625rem + var(--offset));
    bottom: -0.625rem;
    left: 50%;
    transform: rotate(45deg) translateX(-0.5px) translateY(-0.5px);
    transform-origin: center;
    box-shadow: 2px 2px 5px rgba(49, 49, 48, 0.15);
    background-color: white;
    clip-path: polygon(150% -50%, 150% 150%, -50% 150%);
  }
}

.o-citation__close-button {
  padding: 0.75em;
  margin-left: auto;
  width: 2.25em;
  height: 2.25em;
  display: block;
  position: absolute;
  right: -0.125rem;
  top: -0.125rem;
}

.o-citation__close-button svg {
  fill: none;
  stroke: #a8a8a8;
  stroke-width: 4;
  display: block;
  width: 0.75em;
  height: 0.75em;
}

.c-cookie-consent {
  position: fixed;
  z-index: 20;
  bottom: 0.5rem;
  right: 0.5rem;
  margin-left: 0.5rem;
  display: flex;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background-color: white;
  color: #a8a8a8;
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1), visiblity 0ms linear;
  box-shadow: 0 2px 5px rgba(49, 49, 48, 0.2);
}

.c-cookie-consent--visible {
  opacity: 1;
  visibility: visible;
}

.c-cookie-consent__button {
  padding: 0.75rem;
  margin: -0.75rem;
  margin-left: 0.75rem;
}

.c-nav__toggle {
  display: block;
  box-sizing: content-box;
  width: 2rem;
  height: 2rem;
  padding: 0.375rem;
  position: relative;
  z-index: 10;
  right: -0.625rem;
}

@media (min-width: 60em) {
  .c-nav__toggle {
    display: none;
  }
}

.c-nav__wrapper {
  position: relative;
}

@media (max-width: 29.9375em) {
  .c-nav__wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 30em) and (max-width: 59.9375em) {
  .c-nav__wrapper {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (max-width: 59.9375em) {
  .c-nav__wrapper {
    padding-top: 7rem;
    padding-bottom: 3rem;
    position: absolute;
    z-index: 8;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    background-color: white;
    box-shadow: 0 2px 5px rgba(49, 49, 48, 0.2);
  }
}

@media (max-width: 59.9375em) {
  .c-nav__list {
    font-size: 1.5rem;
  }
}

@media (min-width: 60em) {
  .c-nav__list {
    margin-top: 0.25rem;
  }
}

.c-nav__sub-list {
  font-size: 1.25rem;
  padding-left: 1.25rem;
  padding-top: 0.125rem;
  padding-bottom: 0.625rem;
}

@media (min-width: 60em) {
  .c-nav__sub-list {
    display: none;
  }
}

@media (max-width: 59.9375em) {
  .c-nav__item {
    margin-top: 0.5rem;
  }
}

@media (min-width: 60em) {
  .c-nav__item {
    display: inline-block;
    margin-left: 1.37rem;
  }
  .c-nav__item--hidden {
    display: none;
  }
}

@media (max-width: 59.9375em) {
  .c-nav__sub-item {
    margin-top: 0.5rem;
  }
}

@media (max-width: 59.9375em) {
  .c-nav__wrapper {
    visibility: hidden;
    opacity: 0;
  }
  .c-nav__wrapper--active {
    visibility: visible;
    opacity: 1;
  }
  .c-nav__wrapper--anim {
    transition: opacity 300ms cubic-bezier(0, 0, 0.25, 1), visibility 0ms linear 300ms;
  }
  .c-nav__wrapper--anim.c-nav__wrapper--active {
    transition-delay: 0ms, 0ms;
  }
  .c-nav__item {
    transition: opacity 100ms cubic-bezier(0, 0, 0.25, 1), transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0;
    transform: translateY(1em);
  }
  .c-nav__wrapper--active .c-nav__item {
    transition: opacity 300ms cubic-bezier(0, 0, 0.25, 1), transform 600ms cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 1;
    transform: translateY(0);
  }
  .c-nav__wrapper--active .c-nav__item:nth-child(1) {
    transition-delay: 30ms;
  }
  .c-nav__wrapper--active .c-nav__item:nth-child(2) {
    transition-delay: 60ms;
  }
  .c-nav__wrapper--active .c-nav__item:nth-child(3) {
    transition-delay: 90ms;
  }
  .c-nav__wrapper--active .c-nav__item:nth-child(4) {
    transition-delay: 120ms;
  }
  .c-nav__wrapper--active .c-nav__item:nth-child(5) {
    transition-delay: 150ms;
  }
  .c-nav__wrapper--active .c-nav__item:nth-child(6) {
    transition-delay: 180ms;
  }
  .c-nav__wrapper--active .c-nav__item:nth-child(7) {
    transition-delay: 210ms;
  }
  .c-nav__wrapper--active .c-nav__item:nth-child(8) {
    transition-delay: 240ms;
  }
  .c-nav__wrapper--active .c-nav__item:nth-child(9) {
    transition-delay: 270ms;
  }
  .c-nav__wrapper--active .c-nav__item:nth-child(10) {
    transition-delay: 300ms;
  }
}

.c-form {
  position: relative;
}

.c-form noscript p {
  padding: 1rem 1.25rem;
  display: block;
  margin-bottom: 3rem;
  border: 3px solid #de5959;
}

.c-form--success .c-form__form {
  visibility: hidden;
}

.c-form__row + .c-form__row {
  margin-top: 1.125rem;
}

.c-form__row + .c-form__row--checkbox,
.c-form__row + .c-form__row--radio {
  margin-top: 1.75rem;
}

.c-form__row--checkbox + .c-form__row--checkbox {
  margin-top: 2.5rem;
}

.c-form__row--radio + .c-form__row--checkbox {
  margin-top: 3rem;
}

@media (max-width: 44.9375em) {
  .c-form__row--columns > * + * {
    margin-top: 1rem;
  }
}

@media (min-width: 45em) {
  .c-form__row--columns {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }
  .c-form__row--columns > * {
    width: 48.5%;
  }
}

.c-form__button {
  margin-top: 1.875rem;
}

.c-form__contact {
  margin-top: 1.875rem;
  font-size: 1rem;
}

@media (min-width: 30em) {
  .c-form__contact {
    font-size: calc(0.13889vw + 0.95833rem);
  }
}

@media (min-width: 75em) {
  .c-form__contact {
    font-size: 1.0625rem;
  }
}

.c-form__contact > * + * {
  margin-top: 0.25em;
}

.c-form__error {
  color: #de5959;
  position: relative;
  padding-left: 1.625em;
  margin-top: 1.5rem;
  display: none;
  font-size: 1rem;
}

@media (min-width: 30em) {
  .c-form__error {
    font-size: calc(0.13889vw + 0.95833rem);
  }
}

@media (min-width: 75em) {
  .c-form__error {
    font-size: 1.0625rem;
  }
}

.c-form--error .c-form__error {
  display: block;
}

.c-form__error span {
  background-color: #de5959;
  position: absolute;
  left: 0;
  top: 0.1875em;
  width: 1.125em;
  height: 1.125em;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  line-height: 1.25em;
  font-size: 1em;
}

.c-form__success {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  visibility: hidden;
}

.c-form__success--contact {
  background-color: #f3f3f0;
}

.c-form__success--event {
  background-color: #f3f3f0;
  text-align: center;
}

.c-form__success--newsletter {
  background-color: white;
  text-align: center;
}

.c-form--success .c-form__success {
  visibility: visible;
}

.js-focus-visible .c-form__error:focus:not(.focus-visible), .js-focus-visible
.c-form__success:focus:not(.focus-visible) {
  outline: none;
}

.c-pagination {
  display: flex;
}

.c-pagination__next {
  margin-left: auto;
}

.c-magazine-teaser {
  position: relative;
}

.c-magazine-teaser:hover .c-magazine-teaser__more, .c-magazine-teaser:focus-within .c-magazine-teaser__more {
  color: #313130;
}

.c-magazine-teaser__hl a {
  transition: color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.c-magazine-teaser__hl a:hover, .c-magazine-teaser__hl a:focus {
  color: #00cb76;
}

.c-magazine-teaser__hl a::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.c-magazine-teaser__more::after {
  content: none;
}

.c-text-teaser {
  background-color: white;
  color: #00cb76;
  max-width: 37.5rem;
  padding-top: 2rem;
  padding-bottom: 2.375rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

@media (min-width: 30em) {
  .c-text-teaser {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}

@media (min-width: 75em) {
  .c-text-teaser {
    padding-right: 4.25rem;
    padding-left: 4.25rem;
  }
}

.c-text-teaser--article {
  max-width: none;
}

@media (min-width: 75em) {
  .c-text-teaser--article {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}

.c-social-links + .c-social-links {
  margin-top: 0.25em;
}

.c-social-links__toggle {
  display: inline-flex;
  align-items: center;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.c-social-links__toggle:hover, .c-social-links__toggle:focus {
  color: #00cb76;
}

.c-social-links__toggle:active {
  transform: scale(0.95);
}

.c-social-links__toggle svg {
  width: 1.25em;
  height: 1.25em;
  fill: #a8a8a8;
}

.c-social-links__toggle span {
  margin-left: 0.375em;
  margin-top: 0.0625em;
}

.c-social-links__list {
  padding-top: 0.5rem;
  padding-bottom: 1.75rem;
  display: none;
}

.c-social-links__toggle[aria-expanded='true'] + .c-social-links__list {
  display: block;
}

.c-article-search__hl {
  margin-bottom: 0.75rem;
}

.c-article-search__button {
  margin-top: 1em;
}

.c-popular-tags__hl {
  margin-bottom: 0.75rem;
}

.c-popular-tags__list {
  margin-top: -0.375em;
}

.c-popular-tags__list li {
  display: inline-block;
  margin-right: 0.125em;
  margin-top: 0.375em;
}

.c-event-activity {
  position: relative;
  padding-right: 2.25rem;
}

.c-event-activity + .c-event-activity {
  margin-top: 2rem;
}

@media (min-width: 60em) {
  .c-event-activity {
    width: 50%;
  }
  .c-event-activity:nth-child(even) {
    margin-left: auto;
    padding-right: 0;
    padding-left: 2.25rem;
  }
  .c-event-activity + .c-event-activity {
    margin-top: -2.75rem;
  }
}

.c-event-activity__content {
  background-color: white;
  padding: 1.25rem 1.25rem 1.275rem;
  position: relative;
}

@media (min-width: 30em) {
  .c-event-activity__content {
    padding: 1.75rem 2rem 1.75rem;
  }
}

.c-event-activity--dark .c-event-activity__content {
  background-color: #313130;
  color: white;
}

.c-event-activity--dark .c-event-activity__content::after {
  background: #313130;
}

.c-event-activity__content::after {
  content: '';
  position: absolute;
  top: 2rem;
  right: -0.5rem;
  width: 0;
  height: 0;
  padding: 0.5rem;
  background: white;
  border: inherit;
  border-right: 0;
  border-bottom: 0;
  transform: rotate(45deg);
}

@media (min-width: 60em) {
  .c-event-activity:nth-child(even) .c-event-activity__content::after {
    right: auto;
    left: -0.5rem;
  }
}

.c-event-activity__time {
  margin-bottom: 0.625rem;
}

.c-event-activity__border {
  background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 20'%3E%3Ccircle fill='rgb(0, 203, 118)' cx='5' cy='10' r='4.5'/%3E%3C/svg%3E");
  background-repeat: space;
  position: absolute;
  top: 55px;
  bottom: -56px;
  right: 0;
  width: 10px;
}

.c-event-activity:last-child .c-event-activity__border {
  bottom: 0;
}

@media (min-width: 60em) {
  .c-event-activity__border {
    right: -5px;
    bottom: 19px;
  }
  .c-event-activity:nth-child(even) .c-event-activity__border {
    left: -5px;
    right: auto;
  }
}

.c-event-activity__border:before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  background-color: #313130;
  border-radius: 50%;
  position: absolute;
  top: -22px;
  left: -2px;
  z-index: 2;
}

.c-event-activity:last-child .c-event-activity__border:after {
  content: '';
  display: block;
  height: 90px;
  width: 10px;
  bottom: 0;
  position: absolute;
  background: linear-gradient(to top, #f3f3f0 0%, #f3f3f0 25%, rgba(243, 243, 240, 0.75) 25%, rgba(243, 243, 240, 0.75) 50%, rgba(243, 243, 240, 0.5) 50%, rgba(243, 243, 240, 0.5) 75%, rgba(243, 243, 240, 0.25) 75%, rgba(243, 243, 240, 0.25) 100%);
}

.c-about-numbers {
  color: white;
  position: relative;
}

@media (max-width: 44.9375em) {
  .c-about-numbers > * + * {
    margin-top: 2rem;
  }
}

@media (min-width: 45em) {
  .c-about-numbers {
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
}

@media (max-width: 44.9375em) {
  .c-about-numbers__number {
    display: flex;
    align-items: flex-end;
  }
}

@media (min-width: 45em) {
  .c-about-numbers__number {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.c-about-numbers__number > span:nth-child(1) {
  display: block;
}

@media (max-width: 44.9375em) {
  .c-about-numbers__number > span:nth-child(1) {
    flex-shrink: 0;
  }
}

.c-about-numbers__number > span:nth-child(1) span:nth-child(1) {
  display: inline-block;
  line-height: 1;
  font-size: 2.5rem;
}

@media (min-width: 30em) {
  .c-about-numbers__number > span:nth-child(1) span:nth-child(1) {
    font-size: calc(8.88889vw - 0.16667rem);
  }
}

@media (min-width: 75em) {
  .c-about-numbers__number > span:nth-child(1) span:nth-child(1) {
    font-size: 6.5rem;
  }
}

.c-about-numbers__number > span:nth-child(2) {
  display: block;
}

@media (max-width: 44.9375em) {
  .c-about-numbers__number > span:nth-child(2) {
    margin-left: 1.5rem;
    margin-bottom: 0.125rem;
    line-height: 1.4;
  }
}

@media (min-width: 45em) {
  .c-about-numbers__number > span:nth-child(2) {
    margin-top: 1rem;
  }
}

.c-about-numbers__number .o-citation button:hover, .c-about-numbers__number .o-citation button:focus {
  color: #313130;
}

.l-header--fixed::after {
  content: '';
  display: block;
  height: 4.5rem;
}

@media (min-width: 45em) {
  .l-header--fixed::after {
    height: 6rem;
  }
}

.l-header__wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 6;
}

@media (min-width: 30em) {
  .l-header__wrapper {
    padding-left: 2.5rem;
  }
}

@media (min-width: 75em) {
  .l-header__wrapper {
    padding-left: 4.25rem;
  }
}

@media (min-width: 30em) {
  .l-header__wrapper {
    padding-right: 2.5rem;
  }
}

@media (min-width: 75em) {
  .l-header__wrapper {
    padding-right: 4.25rem;
  }
}

@media (min-width: 45em) {
  .l-header__wrapper {
    height: 6rem;
  }
}

.l-header--fixed .l-header__wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100rem;
}

.l-header__wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 2;
}

.l-header--fixed .l-header__wrapper::after,
.l-header--contact-visible .l-header__wrapper::after {
  box-shadow: 0 2px 5px rgba(49, 49, 48, 0.2);
}

.l-header__logo {
  display: block;
  position: relative;
  z-index: 10;
  width: 9rem;
  height: 1.5rem;
}

@media (min-width: 45em) {
  .l-header__logo {
    width: 11.25rem;
    height: 1.875rem;
  }
}

.l-header--group .l-header__logo {
  width: 12.75rem;
  height: 1.75rem;
}

@media (min-width: 45em) {
  .l-header--group .l-header__logo {
    width: 15.9375rem;
    height: 2.1875rem;
  }
}

.l-header__logo svg {
  fill: #00cb76;
  display: block;
  width: 100%;
  height: 100%;
}

.l-header__logo path:nth-of-type(2) {
  fill: #a8a8a8;
}

.l-header__nav {
  z-index: 4;
  margin-left: auto;
}

.l-header--group .l-header__nav {
  display: none;
}

.l-header__quicknav {
  background-color: white;
  box-shadow: 0 2px 5px rgba(49, 49, 48, 0.2);
  padding: 0.25rem 0.5rem;
  display: inline-flex;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;
  font-size: 0.9375rem;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 100ms cubic-bezier(0, 0, 0.25, 1), visibility 0ms linear 100ms, transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

@media (min-width: 100rem) {
  .l-header__quicknav {
    right: calc((100% - 100rem) / 2);
  }
}

@media (min-width: 30em) {
  .l-header__quicknav {
    font-size: calc(0.55556vw + 0.77083rem);
  }
}

@media (min-width: 75em) {
  .l-header__quicknav {
    font-size: 1.1875rem;
  }
}

.l-header--group .l-header__quicknav {
  display: none;
}

.l-header--quicknav-visible .l-header__quicknav {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms cubic-bezier(0, 0, 0.25, 1), visibility 0ms linear 0ms, transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.l-header__quicknav--menu-only > *:nth-child(1) {
  display: none;
}

.l-header__quicknav > *:nth-child(1) {
  margin-right: 0.375rem;
}

.l-header__contact {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 6rem;
  padding-bottom: 3rem;
  box-shadow: 0 2px 5px rgba(49, 49, 48, 0.2);
  background-color: #f3f3f0;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  height: 0;
  min-height: auto;
  transform: translateY(3rem);
  transform-origin: top;
  transition: opacity 100ms cubic-bezier(0, 0, 0.25, 1), visibility 0ms linear 100ms, transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

@media (min-width: 30em) {
  .l-header__contact {
    padding-left: 2.5rem;
  }
}

@media (min-width: 75em) {
  .l-header__contact {
    padding-left: 4.25rem;
  }
}

@media (min-width: 30em) {
  .l-header__contact {
    padding-right: 2.5rem;
  }
}

@media (min-width: 75em) {
  .l-header__contact {
    padding-right: 4.25rem;
  }
}

@media (min-width: 45em) {
  .l-header__contact {
    padding-top: 8rem;
  }
}

.l-header--group .l-header__contact {
  display: none;
}

.l-header--contact-visible .l-header__contact {
  visibility: visible;
  opacity: 1;
  height: auto;
  min-height: 100vh;
  transform: translateY(0);
  transition: opacity 200ms cubic-bezier(0, 0, 0.25, 1), visibility 0ms linear 0ms, transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.l-header__contact-close {
  padding: 0.5em;
  margin-left: auto;
  width: 2.25em;
  height: 2.25em;
  display: block;
  position: relative;
  right: -0.5em;
}

.l-header__contact-close svg {
  fill: none;
  stroke: #313130;
  stroke-width: 3.5;
  display: inline-block;
  width: 100%;
  height: 100%;
  display: block;
}

.l-footer {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3.75rem;
  padding-bottom: 3.25rem;
  background-color: #00cb76;
  color: white;
}

@media (min-width: 30em) {
  .l-footer {
    padding-left: 2.5rem;
  }
}

@media (min-width: 75em) {
  .l-footer {
    padding-left: 4.25rem;
  }
}

@media (min-width: 30em) {
  .l-footer {
    padding-right: 2.5rem;
  }
}

@media (min-width: 75em) {
  .l-footer {
    padding-right: 4.25rem;
  }
}

@media (max-width: 44.9375em) {
  .l-footer > * + * {
    margin-top: 3.25rem;
  }
}

@media (min-width: 45em) {
  .l-footer {
    display: flex;
  }
}

.l-footer__logo {
  position: relative;
  display: block;
  width: 9rem;
  height: 1.5rem;
}

@media (min-width: 45em) {
  .l-footer__logo {
    width: 11.25rem;
    height: 1.875rem;
  }
}

.l-footer--group .l-footer__logo {
  width: 12.75rem;
  height: 1.75rem;
}

@media (min-width: 45em) {
  .l-footer--group .l-footer__logo {
    width: 15.9375rem;
    height: 2.1875rem;
  }
}

.l-footer__logo svg {
  fill: white;
  display: block;
  width: 100%;
  height: 100%;
}

.l-footer__nav {
  columns: 2;
  column-gap: 2.5rem;
}

@media (max-width: 44.9375em) {
  .l-footer__nav {
    width: max-content;
  }
}

@media (min-width: 45em) {
  .l-footer__nav {
    margin-left: auto;
  }
}

@media (min-width: 60em) {
  .l-footer__nav {
    column-gap: 3rem;
  }
}

.l-footer__social {
  display: flex;
  align-items: flex-start;
}

@media (min-width: 45em) {
  .l-footer__social {
    margin-left: 3rem;
  }
}

@media (min-width: 60em) {
  .l-footer__social {
    margin-left: 4rem;
  }
}

.l-footer__social a {
  width: 1.375rem;
  height: 1.375rem;
  padding: 0.5rem 0.5rem;
  display: block;
  box-sizing: content-box;
  color: white;
  transition: color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.l-footer__social a:hover, .l-footer__social a:focus {
  color: #313130;
}

.l-footer__social svg {
  width: 100%;
  height: 100%;
  display: block;
  fill: currentColor;
}

.l-article {
  position: relative;
}

.l-article__content {
  max-width: 42.5rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 74.9375em) {
  .l-article__links {
    margin-top: 4rem;
    max-width: 42.5rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 75em) {
  .l-article__links {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10.9375rem;
  }
  .l-article__links > * {
    position: sticky;
    top: 2.5em;
    left: 0;
  }
}

.l-article__links > * > * + * {
  margin-top: 1.75rem;
}

@media (min-width: 45em) {
  .l-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 44.9375em) {
  .l-columns__item + .l-columns__item {
    margin-top: 5rem;
  }
  .l-columns--close .l-columns__item + .l-columns__item {
    margin-top: 3rem;
  }
}

@media (min-width: 45em) {
  .l-columns__item:nth-child(n + 3) {
    margin-top: 7rem;
  }
  .l-columns--close .l-columns__item:nth-child(n + 3) {
    margin-top: 5rem;
  }
}

@media (min-width: 45em) {
  .l-columns__item {
    width: 47%;
    width: calc(50% - 1.875rem);
  }
}

@media (min-width: 75em) {
  .l-columns__item {
    width: calc(50% - 3.1875rem);
  }
}

.l-newsletter {
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 30em) {
  .l-newsletter {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.l-newsletter__wrapper {
  max-width: 43.75rem;
  margin-left: auto;
  margin-right: auto;
}

.l-newsletter__icon {
  margin-left: auto;
  margin-right: auto;
  width: 15.9375rem;
  height: 2.34375rem;
}

.l-newsletter__hl {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  text-align: center;
}

@media (min-width: 30em) {
  .l-newsletter__hl {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

@media (min-width: 60em) {
  .l-contact {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 59.9375em) {
  .l-contact__column + .l-contact__column {
    margin-top: 5rem;
  }
}

@media (min-width: 60em) {
  .l-contact__column {
    width: 29%;
    width: calc((100% - (2 * 4.25rem) - 1px) / 3);
  }
}

.l-contact__icon {
  width: 1.875rem;
  height: 1.875rem;
}

.l-contact__icon svg {
  width: 100%;
  height: 100%;
  fill: #00cb76;
}

.l-contact__hl {
  margin-top: 0.75rem;
  margin-bottom: 1.75rem;
}

.l-contact__address {
  margin-top: -0.5em;
  margin-bottom: 1.5rem;
}

.l-contact__map {
  margin-top: 2.375rem;
}

.l-home-hero {
  position: relative;
  overflow: hidden;
}

.l-home-hero__image {
  position: relative;
}

.l-home-hero__image > *:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
}

.l-home-hero--loop .l-home-hero__image > *:nth-child(1) {
  opacity: 0;
  z-index: -1;
}

.l-home-hero--loop .l-home-hero__image > *:nth-child(2) {
  opacity: 1;
  z-index: 1;
}

.l-home-hero__overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  color: white;
  background: linear-gradient(140deg, #00cb76 25%, #0090e1 150%);
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  display: flex;
  align-items: center;
  transform: translateX(-100%);
  transition: transform 1000ms cubic-bezier(0.23, 1, 0.32, 1);
}

@media (min-width: 45em) {
  .l-home-hero__overlay {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.l-home-hero--anim .l-home-hero__overlay,
.no-js .l-home-hero__overlay {
  transform: translateX(0%);
}

.l-home-hero__hl {
  padding-right: 0.25rem;
  font-size: 1.575rem;
}

@media (min-width: 45em) {
  .l-home-hero__hl {
    padding-right: 1rem;
  }
}

@media (min-width: 30em) {
  .l-home-hero__hl {
    font-size: calc(2.75vw + 0.75rem);
  }
}

@media (min-width: 75em) {
  .l-home-hero__hl {
    font-size: calc(1.875vw + 1.40625rem);
  }
}

@media (min-width: 105em) {
  .l-home-hero__hl {
    font-size: 3.375rem;
  }
}

.l-home-hero__hl > span {
  display: block;
  transform: translateX(-200%);
  transition: transform 1500ms cubic-bezier(0.23, 1, 0.32, 1);
}

.l-home-hero__hl > span:nth-child(1) {
  transition-delay: 0ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(1) {
  transition-delay: 200ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(2) {
  transition-delay: 260ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(3) {
  transition-delay: 320ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(4) {
  transition-delay: 380ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(5) {
  transition-delay: 440ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(6) {
  transition-delay: 500ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(7) {
  transition-delay: 560ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(8) {
  transition-delay: 620ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(9) {
  transition-delay: 680ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(10) {
  transition-delay: 740ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(11) {
  transition-delay: 800ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(12) {
  transition-delay: 860ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(13) {
  transition-delay: 920ms;
}

.l-home-hero__hl > span:nth-child(1) > span:nth-child(14) {
  transition-delay: 980ms;
}

.l-home-hero__hl > span:nth-child(2) {
  transition-delay: 200ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(1) {
  transition-delay: 600ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(2) {
  transition-delay: 660ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(3) {
  transition-delay: 720ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(4) {
  transition-delay: 780ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(5) {
  transition-delay: 840ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(6) {
  transition-delay: 900ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(7) {
  transition-delay: 960ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(8) {
  transition-delay: 1020ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(9) {
  transition-delay: 1080ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(10) {
  transition-delay: 1140ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(11) {
  transition-delay: 1200ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(12) {
  transition-delay: 1260ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(13) {
  transition-delay: 1320ms;
}

.l-home-hero__hl > span:nth-child(2) > span:nth-child(14) {
  transition-delay: 1380ms;
}

.l-home-hero__hl > span:nth-child(3) {
  transition-delay: 400ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(1) {
  transition-delay: 1000ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(2) {
  transition-delay: 1060ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(3) {
  transition-delay: 1120ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(4) {
  transition-delay: 1180ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(5) {
  transition-delay: 1240ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(6) {
  transition-delay: 1300ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(7) {
  transition-delay: 1360ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(8) {
  transition-delay: 1420ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(9) {
  transition-delay: 1480ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(10) {
  transition-delay: 1540ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(11) {
  transition-delay: 1600ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(12) {
  transition-delay: 1660ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(13) {
  transition-delay: 1720ms;
}

.l-home-hero__hl > span:nth-child(3) > span:nth-child(14) {
  transition-delay: 1780ms;
}

.l-home-hero__hl > span:nth-child(4) {
  transition-delay: 600ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(1) {
  transition-delay: 1400ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(2) {
  transition-delay: 1460ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(3) {
  transition-delay: 1520ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(4) {
  transition-delay: 1580ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(5) {
  transition-delay: 1640ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(6) {
  transition-delay: 1700ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(7) {
  transition-delay: 1760ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(8) {
  transition-delay: 1820ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(9) {
  transition-delay: 1880ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(10) {
  transition-delay: 1940ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(11) {
  transition-delay: 2000ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(12) {
  transition-delay: 2060ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(13) {
  transition-delay: 2120ms;
}

.l-home-hero__hl > span:nth-child(4) > span:nth-child(14) {
  transition-delay: 2180ms;
}

.l-home-hero--anim .l-home-hero__hl > span,
.no-js .l-home-hero__hl > span {
  transform: translateX(0%);
}

.l-home-hero__hl > span > span {
  opacity: 0.2;
  transition: opacity 500ms cubic-bezier(0, 0, 0.25, 1);
}

.l-home-hero--anim .l-home-hero__hl > span > span,
.no-js .l-home-hero__hl > span > span {
  opacity: 1;
}

@media (min-width: 45em) {
  .l-news {
    display: flex;
  }
}

.l-news__item {
  background-color: white;
}

@media (min-width: 45em) {
  .l-news__item {
    width: 50%;
  }
}

.l-news__item:nth-child(even) {
  position: relative;
}

.l-news__item:nth-child(even)::before {
  content: '';
  background-color: #f3f3f0;
  position: absolute;
  height: 2px;
  top: -1px;
  width: calc(100% - 2rem);
  left: 1rem;
}

@media (min-width: 30em) {
  .l-news__item:nth-child(even)::before {
    width: calc(100% - 5rem);
    left: 2.5rem;
  }
}

@media (min-width: 45em) {
  .l-news__item:nth-child(even)::before {
    width: 2px;
    height: 80%;
    top: 10%;
    left: -1px;
  }
}

.l-services-hero {
  position: relative;
}

.l-services-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.l-services-hero__overlay--right {
  display: block;
  left: auto;
  right: 0;
  padding-top: 2rem;
}

@media (min-width: 45em) {
  .l-services-hero__overlay--right {
    padding-top: 4.5rem;
    width: 60%;
    text-align: right;
  }
}

.l-services-hero__overlay--white {
  color: white;
}

@media (max-width: 44.9375em) {
  .l-services-hero__hl br {
    display: none;
  }
}

.l-services-hero__hl span {
  display: block;
}

.l-services-hero__hl--large {
  font-size: 1.925rem;
}

@media (min-width: 30em) {
  .l-services-hero__hl--large {
    font-size: calc(4.47222vw + 0.58333rem);
  }
}

@media (min-width: 75em) {
  .l-services-hero__hl--large {
    font-size: 3.9375rem;
  }
}

.l-services-teaser > * + * {
  margin-top: 4rem;
}

@media (min-width: 45em) {
  .l-services-teaser > * + * {
    margin-top: 6rem;
  }
}

@media (max-width: 44.9375em) {
  .l-services-teaser__list > * + * {
    margin-top: 6rem;
  }
}

@media (min-width: 45em) {
  .l-services-teaser__list {
    display: flex;
    flex-wrap: wrap;
  }
  .l-services-teaser__list > * {
    width: 29%;
  }
  .l-services-teaser__list > *:nth-child(3n + 2), .l-services-teaser__list > *:nth-child(3n + 3) {
    margin-left: 6.5%;
  }
  .l-services-teaser__list > *:nth-child(n + 4) {
    margin-top: 6rem;
  }
}

.l-services-teaser__item {
  position: relative;
}

.l-services-teaser__item:hover .l-services-teaser__icon, .l-services-teaser__item:focus-within .l-services-teaser__icon {
  transform: scale(1.05);
}

.l-services-teaser__item:hover .l-services-teaser__more, .l-services-teaser__item:focus-within .l-services-teaser__more {
  background-color: #313130;
}

.l-services-teaser__icon {
  transition: transform 600ms cubic-bezier(0.23, 1, 0.32, 1);
}

.l-services-teaser__hl a {
  transition: color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.l-services-teaser__hl a:hover, .l-services-teaser__hl a:focus {
  color: #00cb76;
}

.l-services-teaser__hl a::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.l-services-teaser__icon {
  max-width: 12.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
  display: block;
}

.l-services-nav {
  background-color: white;
}

@media (max-width: 44.9375em) {
  .l-services-nav {
    display: none;
  }
}

.l-services-nav__list {
  display: flex;
  justify-content: space-between;
}

.l-services-nav__item {
  text-align: center;
  width: 33.33%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.375rem;
  padding-bottom: 1.25rem;
  position: relative;
}

@media (min-width: 30em) {
  .l-services-nav__item {
    padding-left: 2.5rem;
  }
}

@media (min-width: 75em) {
  .l-services-nav__item {
    padding-left: 4.25rem;
  }
}

@media (min-width: 30em) {
  .l-services-nav__item {
    padding-right: 2.5rem;
  }
}

@media (min-width: 75em) {
  .l-services-nav__item {
    padding-right: 4.25rem;
  }
}

.l-services-nav__item + .l-services-nav__item::before {
  content: '';
  width: 2px;
  height: 1.25em;
  background-color: #00cb76;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.l-services-nav__item--active::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.9375em 0.8125em 0.9375em;
  border-color: transparent transparent #f3f3f0 transparent;
  position: absolute;
  left: 50%;
  bottom: -1px;
  transform: translateX(-50%);
}

.l-subservices {
  position: relative;
}

.l-subservices__tabs {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 2;
}

.l-subservices__tabs::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  height: 6px;
  bottom: -6px;
  opacity: 0.15;
  background: linear-gradient(to bottom, #313130 0%, rgba(49, 49, 48, 0.738) 19%, rgba(49, 49, 48, 0.541) 34%, rgba(49, 49, 48, 0.382) 47%, rgba(49, 49, 48, 0.278) 56.5%, rgba(49, 49, 48, 0.194) 65%, rgba(49, 49, 48, 0.126) 73%, rgba(49, 49, 48, 0.075) 80.2%, rgba(49, 49, 48, 0.042) 86.1%, rgba(49, 49, 48, 0.021) 91%, rgba(49, 49, 48, 0.008) 95.2%, rgba(49, 49, 48, 0.002) 98.2%, rgba(49, 49, 48, 0) 100%);
}

.l-subservices__tabs > * {
  width: 33.33%;
}

@media (max-width: 44.9375em) {
  .l-subservices__tabs {
    display: none;
  }
}

.l-subservices__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 1.4;
  position: relative;
  text-align: center;
  padding: 1rem 1rem 0.875rem;
}

@media (min-width: 75em) {
  .l-subservices__tab {
    font-size: 120%;
    padding: 1.125rem 1rem 1rem;
  }
}

.l-subservices__tab:not([aria-selected]) {
  color: #b5b5b5;
}

.l-subservices__tab[aria-selected] {
  color: #00cb76;
}

.l-subservices__tab[aria-selected]::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: -3px;
  left: 0;
  background-color: currentColor;
}

.l-subservices__section {
  position: relative;
  background-color: white;
}

.js-focus-visible .l-subservices__section:focus:not(.focus-visible) {
  outline: none;
}

@media (max-width: 44.9375em) {
  .l-subservices__section + .l-subservices__section {
    margin-top: 3rem;
  }
}

@media (min-width: 45em) {
  .l-subservices__title {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }
}

@media (max-width: 44.9375em) {
  .l-subservices__title {
    position: sticky;
    top: 0;
    z-index: 2;
    text-align: center;
    font-size: 120%;
    padding: 0.75rem 1rem 0.5625rem;
    background-color: white;
    color: #00cb76;
  }
  .l-subservices__title::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    height: 6px;
    bottom: -6px;
    opacity: 0.15;
    background: linear-gradient(to bottom, #313130 0%, rgba(49, 49, 48, 0.738) 19%, rgba(49, 49, 48, 0.541) 34%, rgba(49, 49, 48, 0.382) 47%, rgba(49, 49, 48, 0.278) 56.5%, rgba(49, 49, 48, 0.194) 65%, rgba(49, 49, 48, 0.126) 73%, rgba(49, 49, 48, 0.075) 80.2%, rgba(49, 49, 48, 0.042) 86.1%, rgba(49, 49, 48, 0.021) 91%, rgba(49, 49, 48, 0.008) 95.2%, rgba(49, 49, 48, 0.002) 98.2%, rgba(49, 49, 48, 0) 100%);
  }
}

.l-subservices__list {
  padding-bottom: 2rem;
  padding-top: 1rem;
}

@media (min-width: 45em) {
  .l-subservices__list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
  }
}

.l-subservices__item {
  padding: 1.125rem 1rem;
}

@media (min-width: 30em) {
  .l-subservices__item {
    padding: 2rem 2.5rem;
  }
}

@media (min-width: 45em) {
  .l-subservices__item {
    width: 50%;
    text-align: center;
  }
}

@media (min-width: 75em) {
  .l-subservices__item {
    width: 33.33%;
  }
}

@media (max-width: 44.9375em) {
  .l-subservices__item {
    display: flex;
  }
}

@media (max-width: 44.9375em) {
  .l-subservices__item figure {
    flex: 0 0 2.5rem;
    margin-right: 1rem;
  }
}

@media (max-width: 29.9375em) {
  .l-subservices__item figure {
    flex-basis: 1.875rem;
  }
}

@media (min-width: 45em) {
  .l-subservices__item figure {
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 44.9375em) {
  .l-subservices__item > div {
    min-width: 0;
  }
}

@media (max-width: 59.9375em) {
  .l-offers > * + * {
    margin-top: 2rem;
  }
}

@media (min-width: 60em) {
  .l-offers {
    display: flex;
    justify-content: space-between;
  }
}

.l-offers__item {
  position: relative;
  background-color: #00cb76;
  color: white;
  padding: 3rem 1rem 3rem;
}

@media (min-width: 30em) {
  .l-offers__item {
    padding: 3.5rem 1.75rem 3.25rem;
  }
}

@media (min-width: 75em) {
  .l-offers__item {
    padding: 4rem 2.5rem 3.75rem;
  }
}

@media (max-width: 44.9375em) {
  .l-offers__item + .l-offers__item {
    margin-top: 1rem;
  }
}

.l-offers--1 .l-offers__item {
  width: 100%;
}

@media (min-width: 60em) {
  .l-offers--1 .l-offers__item {
    padding: 4rem 2.5rem 3.75rem;
  }
}

@media (min-width: 90em) {
  .l-offers--1 .l-offers__item {
    padding: 4rem 4.25rem 3.75rem;
  }
}

@media (max-width: 59.9375em) {
  .l-offers--1 .l-offers__item > * + * {
    margin-top: 3.5rem;
  }
}

@media (min-width: 60em) {
  .l-offers--1 .l-offers__item {
    display: flex;
    justify-content: space-between;
  }
  .l-offers--1 .l-offers__item > *:first-child {
    flex: 1 1 auto;
    max-width: 42.5rem;
  }
  .l-offers--1 .l-offers__item > *:last-child {
    flex: 0 0 18.75rem;
    margin-left: 7rem;
  }
}

@media (min-width: 60em) {
  .l-offers--2 .l-offers__item {
    width: 48%;
    width: calc(50% - 0.25rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.l-offers--2 .l-offers__item > * + * {
  margin-top: 3.5rem;
}

@media (min-width: 60em) {
  .l-offers--3 .l-offers__item {
    width: 30%;
    width: calc((100% / 3) - 0.25rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.l-offers--3 .l-offers__item > * + * {
  margin-top: 3.5rem;
}

.l-offers__item::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1rem 1.75rem 0 1.75rem;
  border-color: #f3f3f0 transparent transparent transparent;
}

.l-offers__item > div:nth-child(1) > * + * {
  margin-top: 0.75rem;
}

.l-offers__item > div:nth-child(2) {
  color: #313130;
}

.l-offers__item > div:nth-child(2) > h4 {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 85%;
  margin-bottom: 0.75rem;
}

.l-offers__item > div:nth-child(2) > p {
  line-height: 1.4;
}

.l-offers__item > div:nth-child(2) > p span {
  display: block;
}

.l-offers__item > div:nth-child(2) > p span:first-child {
  font-weight: bold;
}

.l-offers__item > div:nth-child(2) > p + p {
  margin-top: 1.125rem;
}

.l-offers__item a {
  transition: color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.l-offers__item a:hover, .l-offers__item a:focus {
  color: white;
}

.l-offers__item abbr {
  display: inline-block;
  width: 0.75em;
  border-bottom: none;
  text-decoration: none;
}

.l-cases-teaser {
  position: relative;
}

.l-cases-teaser__text {
  margin-top: 1.25rem;
}

@media (min-width: 45em) {
  .l-cases-teaser__text {
    width: 50%;
    margin-top: 1.5rem;
  }
}

@media (min-width: 45em) {
  .l-cases-teaser__hl {
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 4rem;
    padding-left: 2.5rem;
  }
}

@media (min-width: 75em) {
  .l-cases-teaser__hl {
    padding-top: 5rem;
    padding-left: 4.25rem;
  }
}

.l-cases-teaser__hl a {
  transition: color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.l-cases-teaser__hl a:hover, .l-cases-teaser__hl a:focus {
  color: #00cb76;
}

.l-author {
  background-color: white;
  padding-top: 2.25rem;
  padding-bottom: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 30em) {
  .l-author {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4.25rem;
    padding-right: 4.25rem;
  }
}

@media (min-width: 45em) {
  .l-author {
    display: flex;
  }
}

@media (max-width: 74.9375em) {
  .l-author {
    flex-wrap: wrap;
  }
}

@media (max-width: 74.9375em) {
  .l-author__hl {
    width: 100%;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 29.9375em) {
  .l-author__hl {
    margin-bottom: 2rem;
  }
}

@media (min-width: 75em) {
  .l-author__hl {
    order: 1;
    margin-left: auto;
  }
}

@media (min-width: 45em) {
  .l-author__image {
    flex: 0 1 15rem;
  }
}

@media (max-width: 44.9375em) {
  .l-author__data {
    margin-top: 1.125rem;
  }
}

@media (min-width: 45em) {
  .l-author__data {
    padding-left: 2rem;
    flex: 1;
  }
}

@media (min-width: 75em) {
  .l-author__data {
    padding-right: 4rem;
  }
}

.l-author__text {
  margin-top: 1.25rem;
  flex: 0 1 auto;
}

.l-author__contact {
  color: #00cb76;
  margin-top: 2rem;
}

.l-author__contact a {
  transition: color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.l-author__contact a:hover, .l-author__contact a:focus {
  color: #313130;
}

.l-author__contact abbr {
  display: inline-block;
  width: 0.75em;
  border-bottom: none;
  text-decoration: none;
}

@media (min-width: 45em) {
  .l-management {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 44.9375em) {
  .l-management__item + .l-management__item {
    margin-top: 4rem;
  }
}

@media (min-width: 45em) {
  .l-management__item:nth-child(n + 4) {
    margin-top: 6rem;
  }
}

@media (min-width: 45em) {
  .l-management__item {
    width: 47%;
    width: calc((100% / 3) - 1.875rem);
  }
}

@media (min-width: 75em) {
  .l-management__item {
    width: calc((100% / 3) - 3.1875rem);
  }
}

.l-image-teaser {
  position: relative;
}

@media (max-width: 44.9375em) {
  .l-image-teaser__overlay {
    padding-top: 2.5rem;
    padding-bottom: 3rem;
    background-color: white;
  }
}

@media (min-width: 45em) {
  .l-image-teaser__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 57%;
    height: 100%;
    padding-top: 2.5rem;
  }
  .l-image-teaser__overlay--right {
    right: 0;
    left: auto;
    width: 53%;
  }
}

@media (min-width: 60em) {
  .l-image-teaser__overlay {
    padding-top: 4.5rem;
  }
}

@media (min-width: 45em) {
  .l-image-teaser__overlay--white {
    color: white;
  }
  .l-image-teaser__overlay--white a,
  .l-image-teaser__overlay--white span {
    color: white;
  }
  .l-image-teaser__overlay--white a:hover, .l-image-teaser__overlay--white a:focus,
  .l-image-teaser__overlay--white span:hover,
  .l-image-teaser__overlay--white span:focus {
    color: #313130;
  }
}

.l-image-teaser__overlay > div {
  position: relative;
}

.l-image-teaser__overlay > div:hover .l-image-teaser__more, .l-image-teaser__overlay > div:focus-within .l-image-teaser__more {
  color: #313130;
}

.l-image-teaser__hl a {
  transition: color 150ms cubic-bezier(0, 0, 0.25, 1);
}

.l-image-teaser__hl a:hover, .l-image-teaser__hl a:focus {
  color: #00cb76;
}

@media (min-width: 45em) {
  .l-image-teaser__overlay--white .l-image-teaser__hl a:hover, .l-image-teaser__overlay--white .l-image-teaser__hl a:focus {
    color: #313130;
  }
}

.l-image-teaser__hl a::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.l-image-teaser__more::after {
  content: none;
}

.l-map-section {
  position: relative;
}

@media (min-width: 45em) {
  .l-map-section__overlay--white {
    color: white;
  }
}

@media (max-width: 44.9375em) {
  .l-map-section__overlay {
    padding-top: 2.5rem;
    padding-bottom: 3rem;
    background-color: white;
  }
}

@media (min-width: 45em) {
  .l-map-section__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 57%;
    height: 100%;
    padding-top: 2.5rem;
  }
}

@media (min-width: 60em) {
  .l-map-section__overlay {
    padding-top: 4.5rem;
  }
}

.l-map-section__map {
  position: relative;
}

@media (min-width: 45em) {
  .l-map-section__map {
    width: 60%;
    margin-left: auto;
  }
}

.l-map-section__map-anim {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.l-map-section__legend {
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0 10px rgba(49, 49, 48, 0.1);
}

@media (max-width: 44.9375em) {
  .l-map-section__legend > * + * {
    margin-top: 2rem;
  }
}

@media (min-width: 45em) {
  .l-map-section__legend {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: space-between;
  }
  .l-map-section__legend > * {
    width: 30%;
  }
}

.l-map-section__legend > div {
  padding-left: 2.5rem;
  position: relative;
}

@media (min-width: 45em) {
  .l-map-section__legend > div {
    padding-left: 3rem;
  }
}

.l-map-section__legend > div::before {
  content: '';
  position: absolute;
  top: 0.1875em;
  left: 0;
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
}

.l-map-section__legend > div:nth-child(1)::before {
  background-color: #00cb76;
}

.l-map-section__legend > div:nth-child(2)::before {
  background-color: #66e0ad;
}

.l-map-section__legend > div:nth-child(3)::before {
  background-color: #d0d0c9;
}

.l-quote {
  position: relative;
}

@media (max-width: 44.9375em) {
  .l-quote__overlay {
    padding-top: 1.75rem;
    padding-bottom: 2rem;
    background-color: white;
  }
}

@media (min-width: 45em) {
  .l-quote__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 3rem;
  }
}

@media (min-width: 45em) {
  .l-group__text {
    width: 50%;
  }
}

.l-group__logo {
  margin-top: 6rem;
  position: relative;
}

@media (min-width: 45em) {
  .l-group__logo {
    margin-top: 5rem;
  }
}

.l-group__logo > * {
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
  width: 14.375rem;
  height: 2rem;
}

@media (min-width: 45em) {
  .l-group__logo > * {
    width: 19.9375rem;
    height: 2.75rem;
  }
}

.l-group__logo > * svg {
  fill: #00cb76;
  display: block;
  width: 100%;
  height: 100%;
}

.l-group__logo > * path:nth-of-type(2) {
  fill: #a8a8a8;
}

.l-group__wrapper {
  border-top: 2px solid #313130;
  margin-top: 3rem;
}

@media (min-width: 45em) {
  .l-group__wrapper {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 44.9375em) {
  .l-group__column + .l-group__column {
    margin-top: 6rem;
  }
}

@media (min-width: 45em) {
  .l-group__column {
    width: 46%;
  }
}

.l-group__segment {
  text-align: center;
  padding-bottom: 2.75rem;
  margin-top: 2rem;
  border-bottom: 1px solid #313130;
}

.l-group__segment > * + * {
  margin-top: 0.5rem;
}

.l-group__segment figure {
  margin-top: 1rem;
  max-width: 3.5rem;
  margin-left: auto;
  margin-right: auto;
}

.l-group__item {
  margin-top: 4rem;
}

.l-group__item figure {
  max-width: 10.625rem;
}

.l-about-hero__wrapper {
  position: relative;
}

.l-about-hero__image {
  position: relative;
}

.l-about-hero__image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background: linear-gradient(to top, #f3f3f0 0%, rgba(243, 243, 240, 0.738) 19%, rgba(243, 243, 240, 0.541) 34%, rgba(243, 243, 240, 0.382) 47%, rgba(243, 243, 240, 0.278) 56.5%, rgba(243, 243, 240, 0.194) 65%, rgba(243, 243, 240, 0.126) 73%, rgba(243, 243, 240, 0.075) 80.2%, rgba(243, 243, 240, 0.042) 86.1%, rgba(243, 243, 240, 0.021) 91%, rgba(243, 243, 240, 0.008) 95.2%, rgba(243, 243, 240, 0.002) 98.2%, rgba(243, 243, 240, 0) 100%);
}

@media (min-width: 45em) {
  .l-about-hero__image::after {
    height: 7rem;
  }
}

.l-about-hero__overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
}

@media (min-width: 45em) {
  .l-about-hero__overlay {
    padding-bottom: 10rem;
  }
}

.l-about-hero__numbers {
  flex: 1;
}

.l-about-hero__numbers > * {
  position: sticky;
  top: 4rem;
}

@media (min-width: 45em) {
  .l-about-hero__numbers > * {
    top: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 44.9375em) {
  .l-about-hero__number {
    display: flex;
    align-items: center;
  }
  .l-about-hero__number + .l-about-hero__number {
    margin-top: 1.5rem;
  }
}

@media (min-width: 45em) {
  .l-about-hero__number {
    text-align: center;
    width: 30%;
  }
}

.l-about-hero__number > span:nth-child(1) {
  display: block;
}

@media (max-width: 44.9375em) {
  .l-about-hero__number > span:nth-child(1) {
    flex: 0 0 30%;
  }
}

@media (max-width: 29.9375em) {
  .l-about-hero__number > span:nth-child(1) {
    flex: 0 0 6.625rem;
  }
}

.l-about-hero__number > span:nth-child(1) span:nth-child(1) {
  display: inline-block;
  line-height: 1;
  font-size: 3rem;
}

@media (min-width: 30em) {
  .l-about-hero__number > span:nth-child(1) span:nth-child(1) {
    font-size: calc(15.55556vw - 1.66667rem);
  }
}

@media (min-width: 75em) {
  .l-about-hero__number > span:nth-child(1) span:nth-child(1) {
    font-size: 10rem;
  }
}

.l-about-hero__number > span:nth-child(1) span:nth-child(2) {
  display: inline-block;
  margin-left: 0.25rem;
  line-height: 1;
  max-width: 4ch;
  font-size: 1em;
}

@media (min-width: 30em) {
  .l-about-hero__number > span:nth-child(1) span:nth-child(2) {
    font-size: calc(2.22222vw + 0.33333em);
  }
}

@media (min-width: 75em) {
  .l-about-hero__number > span:nth-child(1) span:nth-child(2) {
    font-size: 2em;
  }
}

.l-about-hero__number > span:nth-child(2) {
  display: block;
}

@media (max-width: 44.9375em) {
  .l-about-hero__number > span:nth-child(2) {
    margin-left: 1.5rem;
    line-height: 1.4;
  }
}

@media (min-width: 45em) {
  .l-about-hero__number > span:nth-child(2) {
    margin-top: 1rem;
  }
}

.l-about-hero__article {
  text-align: center;
}

@media (max-width: 44.9375em) {
  .l-about-hero__article {
    margin-top: 2rem;
  }
}

@media (min-width: 45em) {
  .l-about-hero__article {
    margin-top: -3rem;
  }
}

.l-b2c-hero {
  position: relative;
}

.l-b2c-hero--white {
  color: white;
}

.l-b2c-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.l-b2c-hero__link {
  display: block;
  height: 100%;
  transition: opacity 150ms cubic-bezier(0, 0, 0.25, 1);
  opacity: 1;
}

.l-b2c-hero__link:hover, .l-b2c-hero__link:focus {
  opacity: 0.7;
}

.l-b2c-hero__hl {
  line-height: 1.15;
  height: 100%;
  font-size: 1.925rem;
}

@media (min-width: 30em) {
  .l-b2c-hero__hl {
    font-size: calc(4.72222vw + 0.50833rem);
  }
}

@media (min-width: 75em) {
  .l-b2c-hero__hl {
    font-size: 4.05rem;
  }
}

.l-b2c-hero__hl span {
  display: block;
}

.l-b2c-hero__hl-text {
  padding-top: 1.5rem;
}

@media (min-width: 45em) {
  .l-b2c-hero__hl-text {
    padding-top: 5rem;
  }
}

.l-event-hero {
  position: relative;
}

.l-event-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.l-event-hero__hl {
  text-align: center;
  padding-top: 1rem;
}

.l-event-hero__hl span {
  display: block;
}

.l-event-hero__hl span:nth-child(1) {
  line-height: 1;
  font-size: 2.1875rem;
}

@media (min-width: 30em) {
  .l-event-hero__hl span:nth-child(1) {
    font-size: calc(3.88889vw + 1.02083rem);
  }
}

@media (min-width: 75em) {
  .l-event-hero__hl span:nth-child(1) {
    font-size: 3.9375rem;
  }
}

.l-event-hero__hl span:nth-child(2) {
  margin-top: 1em;
}

.l-event-agenda {
  overflow: hidden;
}

.l-event-agenda__title {
  text-align: center;
}

.l-event-agenda__subtitle {
  color: #a8a8a8;
  margin-top: 0.5rem;
}

.l-event-agenda__date {
  color: #0090e1;
  text-align: center;
  margin-top: 4rem;
}

.l-event-agenda__list {
  margin-top: 2rem;
  max-width: 62.5rem;
  margin-left: auto;
  margin-right: auto;
}

.l-event-location__map {
  width: 100%;
  height: 50vw;
  max-height: 31.25rem;
  min-height: 18.75rem;
}

.l-event-location__list {
  padding-top: 1.5rem;
  max-width: 56.25rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 60em) {
  .l-event-location__list {
    padding-top: 3rem;
  }
}

@media (min-width: 45em) {
  .l-event-location__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.l-event-location__item {
  background-color: white;
  padding: 1.5rem 1.25rem 1.275rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: flex-start;
}

@media (min-width: 30em) {
  .l-event-location__item {
    padding: 1.875rem 2rem;
  }
}

@media (min-width: 60em) {
  .l-event-location__item {
    width: 48%;
  }
}

.l-event-location__item > *:nth-child(1) {
  flex: 0 0 2.5rem;
  margin-right: 1.75rem;
}

.l-event-location__item > *:nth-child(1) svg {
  width: 100%;
  height: 100%;
  display: block;
}

.l-event-location__item > *:nth-child(2) > * + * {
  margin-top: 0.875rem;
}

.glide {
  position: relative;
  width: 100%;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
}

.glide__slide a,
.glide__arrows,
.glide__bullets {
  user-select: none;
}

.glide__bullets {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 30em) {
  .glide__bullets {
    padding-left: 2.5rem;
  }
}

@media (min-width: 75em) {
  .glide__bullets {
    padding-left: 4.25rem;
  }
}

@media (min-width: 30em) {
  .glide__bullets {
    padding-right: 2.5rem;
  }
}

@media (min-width: 75em) {
  .glide__bullets {
    padding-right: 4.25rem;
  }
}

.glide__bullet {
  width: 1.125rem;
  height: 1.25rem;
  position: relative;
}

.glide__bullet::after {
  content: '';
  display: block;
  transition: background-color 200ms cubic-bezier(0, 0, 0.25, 1);
  background-color: rgba(168, 168, 168, 0.5);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.glide__bullet:hover::after, .glide__bullet:focus::after, .glide__bullet--active::after {
  background-color: #00cb76;
}
