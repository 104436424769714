.l-services-nav {
  background-color: $color-white;

  @media ($max-ms) {
    display: none;
  }
}

.l-services-nav__list {
  display: flex;
  justify-content: space-between;
}

.l-services-nav__item {
  text-align: center;
  width: 33.33%;
  @include base-padding-x;
  padding-top: rem(22);
  padding-bottom: rem(20);
  position: relative;

  & + &::before {
    content: '';
    width: 2px;
    height: 1.25em;
    background-color: $color-green;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &--active::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 em(15 13 15);
    border-color: transparent transparent $color-gray-bg transparent;
    position: absolute;
    left: 50%;
    bottom: -1px;
    transform: translateX(-50%);
  }
}
