.glide {
  position: relative;
  width: 100%;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}

.glide__slides--dragging {
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
}

.glide__slide a,
.glide__arrows,
.glide__bullets {
  user-select: none;
}

// custom styles
.glide__bullets {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: rem(8);
  @include base-padding-x;
}

.glide__bullet {
  width: 18/16 * 1rem;
  height: 20/16 * 1rem;
  position: relative;

  &::after {
    content: '';
    display: block;
    transition: background-color 200ms $ease-fade;
    background-color: rgba($color-gray-medium, 0.5);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover,
  &:focus,
  &--active {
    &::after {
      background-color: $color-green;
    }
  }
}
