@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes move-in {
  from {
    transform: translateY(32px);
  }

  to {
    transform: translateY(0);
  }
}

@media ($min-ms) {
  @keyframes move-in {
    from {
      transform: translateY(48px);
    }

    to {
      transform: translateY(0);
    }
  }
}

.u-anim--in,
.u-anim--in-stagger > * {
  animation: fade-in 1000ms $ease-fade both, move-in 1500ms $ease-out both;
  animation-play-state: paused;

  .no-js & {
    animation-play-state: running;
  }
}

@for $i from 1 through 7 {
  .u-anim--delay-#{$i} {
    animation-delay: $i * 150ms;
  }

  .u-anim--in-stagger > *:nth-child(#{$i}) {
    animation-delay: $i * 150ms;
  }
}

.u-anim--play,
.u-anim--in-stagger.u-anim--play > * {
  animation-play-state: running;
}
