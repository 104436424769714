.o-quicknav-button {
  padding: 0.25em 0.5em;
  display: inline-flex;
  align-items: center;
  transition: transform 300ms $ease-out, color 150ms $ease-fade;

  > * + * {
    margin-left: 0.5em;
  }

  &:hover,
  &:focus {
    color: $color-green;
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    fill: none;
    stroke: currentColor;
    stroke-width: 1.5;
    display: inline-block;
    width: 1.125em;
    height: 1.125em;
  }
}
