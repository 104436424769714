.l-footer {
  @include base-padding-x;
  padding-top: 3.75rem;
  padding-bottom: 3.25rem;
  background-color: $color-green;
  color: $color-white;

  @media ($max-ms) {
    > * + * {
      margin-top: 3.25rem;
    }
  }

  @media ($min-ms) {
    display: flex;
  }
}

.l-footer__logo {
  position: relative;
  display: block;
  width: rem(180 * 0.8);
  height: rem(30 * 0.8);

  @media ($min-ms) {
    width: rem(180);
    height: rem(30);
  }

  .l-footer--group & {
    width: rem(255 * 0.8);
    height: rem(35 * 0.8);

    @media ($min-ms) {
      width: rem(255);
      height: rem(35);
    }
  }

  svg {
    fill: $color-white;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.l-footer__nav {
  columns: 2;
  column-gap: 2.5rem;

  @media ($max-ms) {
    width: max-content;
  }

  @media ($min-ms) {
    margin-left: auto;
  }

  @media ($min-m) {
    column-gap: 3rem;
  }
}

.l-footer__social {
  display: flex;
  align-items: flex-start;

  @media ($min-ms) {
    margin-left: 3rem;
  }

  @media ($min-m) {
    margin-left: 4rem;
  }

  a {
    width: rem(22);
    height: rem(22);
    padding: 0.5rem 0.5rem;
    display: block;
    box-sizing: content-box;
    color: $color-white;
    transition: color 150ms $ease-fade;

    &:hover,
    &:focus {
      color: $color-black;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    fill: currentColor;
  }
}
