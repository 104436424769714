.awesomplete [hidden] {
  display: none;
}

.awesomplete .visually-hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.awesomplete {
  position: relative;
}

.awesomplete > ul {
  position: absolute;
  left: 0;
  z-index: 1;
  min-width: 100%;
  box-sizing: border-box;
  list-style: none;
  padding: 0.375em 0;
  margin-top: 0;
  background: $color-white;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.15);
  text-shadow: none;
  transition: transform 300ms $ease-out, opacity 150ms $ease-fade;
  transform-origin: top;
}

.awesomplete > ul:empty {
  display: none;
}

.awesomplete > ul[hidden],
.awesomplete > ul:empty {
  opacity: 0;
  transform: translateY(-0.25em);
  display: block;
  transition-timing-function: ease;
}

.awesomplete > ul:before {
  content: '';
  position: absolute;
  top: -0.5em;
  left: 1.5em;
  width: 0;
  height: 0;
  padding: 0.5em;
  background: white;
  border: inherit;
  border-right: 0;
  border-bottom: 0;
  transform: rotate(45deg);
}

.awesomplete > ul > li {
  position: relative;
  padding: 0.375em 1em;
  cursor: pointer;
  line-height: 1.3;
}

.awesomplete > ul > li:hover {
  text-decoration: underline;
  color: $color-green;
}

.awesomplete > ul > li[aria-selected='true'] {
  color: $color-green;
}

.awesomplete mark {
  color: $color-green;
  background-color: transparent;
}

.awesomplete li[aria-selected='true'] {
  text-decoration: underline;
}
