// Background color
.u-bg--white {
  background-color: $color-white;
}

.u-bg--green {
  background-color: $color-green;
}

// Widths
.u-mw--article {
  max-width: $max-width-article;
}

// Overflow
.u-o--hidden {
  overflow: hidden;
}

// Text align
.u-ta--center {
  text-align: center;
}

// Base paddings
.u-px--base {
  @include base-padding-x;
}

.u-pt--base {
  padding-top: 3.5rem;

  @media ($min-ms) {
    padding-top: 5rem;
  }
}

.u-pb--base {
  padding-bottom: 6rem;

  @media ($min-ms) {
    padding-bottom: 7.5rem;
  }
}

// Paddings
$paddings: (
  xs: (
    1rem,
    1.5rem
  ),
  s: (
    1.5rem,
    2.5rem
  ),
  ms: (
    2.5rem,
    3.5rem
  ),
  m: (
    3.5rem,
    5rem
  ),
  ml: (
    4rem,
    6rem
  ),
  l: (
    5rem,
    7rem
  ),
  xl: (
    6rem,
    8rem
  )
);

.u-pt {
  @each $name, $value in $paddings {
    &--#{$name} {
      padding-top: nth($value, 1);

      @media ($min-ms) {
        padding-top: nth($value, 2);
      }
    }
  }
}

.u-pb {
  @each $name, $value in $paddings {
    &--#{$name} {
      padding-bottom: nth($value, 1);

      @media ($min-ms) {
        padding-bottom: nth($value, 2);
      }
    }
  }
}

// Text spacing
.u-text-spacing {
  // Default
  > * + * {
    margin-top: 1.5em;
  }

  // Headlines
  > .o-hl--1 + * {
    margin-top: 2em;
  }
  > .o-hl--2 + * {
    margin-top: 1.5em;
  }
  > .o-hl--3 + * {
    margin-top: 0.5em;
  }

  > * + .o-hl--1 {
    margin-top: 4.5rem;
  }
  > * + .o-hl--2 {
    margin-top: 4.5rem;
  }
  > * + .o-hl--3 {
    margin-top: 3rem;
  }

  > .o-hl--1 + .o-hl--2 {
    margin-top: 3rem;
  }
  > .o-hl--2 + .o-hl--3 {
    margin-top: 2.5rem;
  }
  > .o-service-icon + .o-hl--2 {
    margin-top: 3.5rem;
  }

  // Other elements
  > .o-quote + * {
    margin-top: 3.5rem;
  }
  > .o-figure + * {
    margin-top: 3.5rem;
  }
  > .c-text-teaser + * {
    margin-top: 3rem;
  }

  > * + .o-quote {
    margin-top: 3.5rem;
  }
  > * + .o-figure {
    margin-top: 3.5rem;
  }
  > * + .c-text-teaser {
    margin-top: 3rem;
  }

  > p > .o-button {
    margin-top: 1rem;
    margin-bottom: 1.25rem;
  }

  .o-figure--small {
    max-width: rem(250);
    margin-left: auto;
    margin-right: auto;
  }
}

.u-teaser-spacing {
  // Default
  > * + * {
    margin-top: 0.875rem;
  }

  > * + .o-hl--1 {
    margin-top: 1.375rem;
  }

  > * + .o-button {
    margin-top: 1.75rem;
  }

  > .o-category + .o-hl--1 {
    margin-top: 1rem;
  }

  > .o-category + .o-hl--2 {
    margin-top: 0.625rem;
  }

  > .o-service-icon + .o-hl--3 {
    margin-top: 2.25rem;

    @media ($min-ms) {
      margin-top: 3.5rem;
    }
  }

  > .o-hl--1 + * {
    margin-top: 1rem;
  }

  > .o-hl--3 + * {
    margin-top: 0;
  }
}

// Animation
.u-anim-hidden {
  opacity: 0;

  .no-js & {
    opacity: 1;
  }
}

// A11y
.u-a11y-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
