.o-input {
  // Honeypot
  &--hnpt {
    position: absolute;
    left: -9999px;
  }
}

.o-input__label {
  margin-bottom: em(2);

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-form-small, 1),
      $ml: nth($font-size-form-small, 2)
    )
  );
}

.o-input__input,
.o-input__select {
  padding: rem(7 13 6);
  border: 1px solid rgba($color-gray-medium, 0.5);
  color: $color-black;
  background-color: $color-white;
  outline-offset: -2px;
  transition: outline 150ms $ease-fade;
  outline: 2px solid transparent;

  .o-input--light & {
    background-color: lighten($color-gray-bg, 3);
  }

  &:focus {
    outline-color: $color-green;
  }

  // improvements: invalid on blur, message + icon for errors
}

.o-input__input {
  &::placeholder {
    color: $color-gray-medium;
    opacity: 1;
  }

  // Fixes extra padding left in Safari for type="search"
  &[type='search']::-webkit-search-decoration {
    appearance: none;
  }
}

.o-input__select {
  background-repeat: no-repeat;
  background-position: right 0.875em center;
  background-size: 1.25em;
  background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 13'%3E%3Cpolyline fill='none' stroke='rgb(168, 168, 168)' stroke-width='2.5' points='1,1 12,11.5 23,1'/%3E%3C/svg%3E");

  // "Placeholder" color
  &:invalid {
    color: $color-gray-medium;
  }

  // Reset Firefox option color
  option {
    color: $color-black;
  }

  // Remove arrow in IE
  &::-ms-expand {
    display: none;
  }

  // Undo Firefox focus ring
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $color-black;
  }
}

.o-input__suggestions {
  display: none;
}
