.o-link {
  position: relative;
  display: inline-block;
  transition: transform 300ms $ease-out, color 150ms $ease-fade;

  // Extend clickable area without affecting underline position
  &--extended::after {
    @include extended-clickarea;
  }

  &:hover,
  &:focus,
  &--active {
    color: $color-green;
  }

  &--white {
    color: $color-white;

    &:hover,
    &:focus,
    &.o-link--active {
      color: $color-black;
    }
  }

  &--green {
    color: $color-green;

    &:hover,
    &:focus,
    &.o-link--active {
      color: $color-black;
    }
  }

  &--sub-active {
    @media ($max-m) {
      color: inherit;
    }
  }

  &:active {
    transform: scale(0.95);
  }

  &--more {
    text-decoration: underline;
    color: $color-green;

    &:hover,
    &:focus {
      color: $color-black;
    }

    &::after {
      @include extended-clickarea;
    }
  }
}

.o-link__icon {
  width: em(12);
  display: inline-block;

  &:last-child {
    text-align: right;
  }

  &--svg {
    width: em(17);
    height: em(17);
    position: relative;
    top: em(3);
    margin-right: em(8);

    svg {
      width: 100%;
      height: 100%;
      display: block;
      fill: $color-gray-medium;
    }
  }
}
