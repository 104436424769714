.l-b2c-hero {
  position: relative;

  &--white {
    color: $color-white;
  }
}

.l-b2c-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.l-b2c-hero__link {
  display: block;
  height: 100%;
  transition: opacity 150ms $ease-fade;
  opacity: 1;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.l-b2c-hero__hl {
  line-height: 1.15;
  height: 100%;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-hl-1, 1) * 1.1,
      $ml: nth($font-size-hl-1, 2) * 1.8
    )
  );

  span {
    display: block;
  }
}

.l-b2c-hero__hl-text {
  padding-top: 1.5rem;

  @media ($min-ms) {
    padding-top: 5rem;
  }
}
