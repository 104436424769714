.c-social-links {
  & + & {
    margin-top: 0.25em;
  }
}

.c-social-links__toggle {
  display: inline-flex;
  align-items: center;
  transition: transform 300ms $ease-out, color 150ms $ease-fade;

  &:hover,
  &:focus {
    color: $color-green;
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    width: em(20);
    height: em(20);
    fill: $color-gray-medium;
  }

  span {
    margin-left: 0.375em;
    margin-top: em(1);
  }
}

.c-social-links__list {
  padding-top: 0.5rem;
  padding-bottom: 1.75rem;
  display: none;

  .c-social-links__toggle[aria-expanded='true'] + & {
    display: block;
  }

  // social buttons, try to position them as good as possible
  // &--buttons {
  //   li {
  //     line-height: 0;

  //     & + li {
  //       margin-top: 0.625rem;
  //     }

  //     * {
  //       line-height: 0 !important;
  //     }

  //     span {
  //       vertical-align: top !important;
  //       height: 20px;
  //     }
  //   }
  // }
}
