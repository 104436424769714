.l-newsletter {
  background-color: $color-white;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: $base-padding-x-s * 1.5;
  padding-right: $base-padding-x-s * 1.5;

  @media ($min-s) {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: $base-padding-x-m;
    padding-right: $base-padding-x-m;
  }
}

.l-newsletter__wrapper {
  max-width: rem(700);
  margin-left: auto;
  margin-right: auto;
}

.l-newsletter__icon {
  margin-left: auto;
  margin-right: auto;
  width: rem(170) * 1.5;
  height: rem(25) * 1.5;
}

.l-newsletter__hl {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  text-align: center;

  @media ($min-s) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
