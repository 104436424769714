.l-subservices {
  position: relative;
}

// Tabs
.l-subservices__tabs {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: $color-white;
  position: sticky;
  top: 0;
  z-index: 2;

  // Shadow
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    height: 6px;
    bottom: -6px;
    opacity: 0.15;
    background: linear-gradient(to bottom, soft-gradient($color-black));
  }

  > * {
    width: 33.33%;
  }

  @media ($max-ms) {
    display: none;
  }
}

.l-subservices__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 1.4;
  position: relative;
  text-align: center;
  padding: 1rem 1rem 0.875rem;

  @media ($min-ml) {
    font-size: 120%;
    padding: 1.125rem 1rem 1rem;
  }

  &:not([aria-selected]) {
    color: lighten($color-gray-medium, 5);
  }

  &[aria-selected] {
    color: $color-green;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -3px;
      left: 0;
      background-color: currentColor;
    }
  }
}

// Sections
.l-subservices__section {
  position: relative;
  background-color: $color-white;

  // hide focus outline b/c js adds tabindex
  .js-focus-visible &:focus:not(.focus-visible) {
    outline: none;
  }

  @media ($max-ms) {
    & + & {
      margin-top: 3rem;
    }
  }
}

.l-subservices__title {
  // A11Y hidden
  @media ($min-ms) {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }

  @media ($max-ms) {
    position: sticky;
    top: 0;
    z-index: 2;
    text-align: center;
    font-size: 120%;
    padding: rem(12) 1rem rem(9);
    background-color: $color-white;
    color: $color-green;

    // Shadow
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      height: 6px;
      bottom: -6px;
      opacity: 0.15;
      background: linear-gradient(to bottom, soft-gradient($color-black));
    }
  }
}

.l-subservices__list {
  padding-bottom: 2rem;
  padding-top: 1rem;

  @media ($min-ms) {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
  }
}

.l-subservices__item {
  padding: 1.125rem $base-padding-x-s;

  @media ($min-s) {
    padding: 2rem $base-padding-x-m;
  }
  // Widths
  @media ($min-ms) {
    width: 50%;
    text-align: center;
  }

  @media ($min-ml) {
    width: 33.33%;
  }

  @media ($max-ms) {
    display: flex;
  }

  // Icon
  figure {
    @media ($max-ms) {
      flex: 0 0 rem(40);
      margin-right: 1rem;
    }

    @media ($max-s) {
      flex-basis: rem(30);
    }

    @media ($min-ms) {
      width: rem(80);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1.5rem;
    }
  }

  // Text
  > div {
    @media ($max-ms) {
      min-width: 0;
    }
  }
}
