.o-hl--1 {
  font-weight: bold;
  line-height: $line-height-narrow;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-hl-1, 1),
      $ml: nth($font-size-hl-1, 2)
    )
  );
}

.o-hl--2 {
  font-weight: bold;
  line-height: $line-height-medium;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-hl-2, 1),
      $ml: nth($font-size-hl-2, 2)
    )
  );

  &--light {
    font-weight: normal;
  }
}

.o-hl--3 {
  font-weight: bold;
}
