.o-citation {
  position: relative;
}

.o-citation__toggle {
  position: relative;

  &:hover,
  .o-citation--active & {
    color: $color-green;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    position: absolute;
    top: -0.25rem;
    left: -0.75rem;
    padding: 0.25rem 0.75rem;
  }
}

.o-citation__popover {
  text-align: center;
  font-size: 90%;
  color: $color-gray-dark;
  position: absolute;
  background-color: $color-white;
  box-shadow: $box-shadow-default;
  z-index: -2;
  visibility: hidden;
  transform: translateY(-2rem);
  width: 0;
  height: 0;
  left: 0;
  opacity: 0;

  .o-citation--active & {
    padding: 0.875em 1.75em em(13);
    width: rem(300);
    height: auto;
    z-index: 2;
    visibility: visible;
    transform: translateY(-1rem);
    opacity: 1;
    transition-duration: 0ms, 0ms, 0ms;
    transition: opacity 200ms $ease-fade, transform 300ms $ease-out,
      visibility 0ms linear;
  }

  // hide focus outline b/c js adds tabindex
  .js-focus-visible &:focus:not(.focus-visible) {
    outline: none;
  }

  --offset: 0px;

  // Arrow
  @supports (clip-path: polygon(0 0)) {
    &::after {
      content: '';
      position: absolute;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: -0.625rem;
      margin-left: calc(-0.625rem + var(--offset));
      bottom: -0.625rem;
      left: 50%;
      transform: rotate(45deg) translateX(-0.5px) translateY(-0.5px);
      transform-origin: center;
      box-shadow: 2px 2px 5px rgba($color-black, 0.15);
      background-color: $color-white;
      clip-path: polygon(150% -50%, 150% 150%, -50% 150%);
    }
  }
}

.o-citation__close-button {
  padding: 0.75em;
  margin-left: auto;
  width: 2.25em;
  height: 2.25em;
  display: block;
  position: absolute;
  right: -0.125rem;
  top: -0.125rem;

  svg {
    fill: none;
    stroke: $color-gray-medium;
    stroke-width: 4;
    display: block;
    width: 0.75em;
    height: 0.75em;
  }
}
