.l-author {
  background-color: $color-white;
  padding-top: 2.25rem;
  padding-bottom: 3rem;
  padding-left: $base-padding-x-s * 1.5;
  padding-right: $base-padding-x-s * 1.5;

  @media ($min-s) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: $base-padding-x-l;
    padding-right: $base-padding-x-l;
  }

  @media ($min-ms) {
    display: flex;
  }

  @media ($max-ml) {
    flex-wrap: wrap;
  }
}

.l-author__hl {
  @media ($max-ml) {
    width: 100%;
    margin-bottom: 2.5rem;
  }

  @media ($max-s) {
    margin-bottom: 2rem;
  }

  @media ($min-ml) {
    order: 1;
    margin-left: auto;
  }
}

.l-author__image {
  @media ($min-ms) {
    flex: 0 1 rem(240);
  }
}

.l-author__data {
  @media ($max-ms) {
    margin-top: 1.125rem;
  }

  @media ($min-ms) {
    padding-left: 2rem;
    flex: 1;
  }

  @media ($min-ml) {
    padding-right: 4rem;
  }
}

.l-author__text {
  margin-top: 1.25rem;
  flex: 0 1 auto;
}

.l-author__contact {
  color: $color-green;
  margin-top: 2rem;

  a {
    transition: color 150ms $ease-fade;

    &:hover,
    &:focus {
      color: $color-black;
    }
  }

  abbr {
    display: inline-block;
    width: 0.75em;
    border-bottom: none;
    text-decoration: none;
  }
}
