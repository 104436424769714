.o-button {
  display: inline-block;
  position: relative;
  background-color: $color-green;
  color: $color-white;
  padding: em(5 24 5);
  border-radius: 1px;
  transition: transform 300ms $ease-out, background-color 150ms $ease-fade;

  &:not(&--loading) {
    &:hover,
    &:focus {
      background-color: $color-black;
    }

    &:active {
      transform: scale(0.95);
    }
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: $color-gray-medium;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-gray-medium;
      transform: none;
    }
  }
}

.o-button__text {
  display: block;
  transition: transform 300ms $ease-fade, opacity 200ms $ease-fade;

  .o-button--loading & {
    opacity: 0;
    transform: scale(0.75);
  }
}

@keyframes button-spinner-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.o-button__spinner {
  width: em(18);
  height: em(18);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: em(-9);
  margin-left: em(-9);
  opacity: 0;
  transform: scale(0.25);
  transition: transform 300ms $ease-fade, opacity 200ms $ease-fade;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    border-top: 2px solid rgba($color-white, 0);
    border-left: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
    border-right: 2px solid $color-white;
    border-radius: 50%;
    transform-origin: center center;
  }

  .o-button--loading & {
    opacity: 1;
    transform: scale(1);

    &::after {
      animation: button-spinner-anim 1s linear infinite;
    }
  }
}
