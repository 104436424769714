// Base padding
@mixin base-padding-x($left: true, $right: true) {
  @if ($left) {
    padding-left: $base-padding-x-s;

    @media ($min-s) {
      padding-left: $base-padding-x-m;
    }

    @media ($min-ml) {
      padding-left: $base-padding-x-l;
    }
  }

  @if ($right) {
    padding-right: $base-padding-x-s;

    @media ($min-s) {
      padding-right: $base-padding-x-m;
    }

    @media ($min-ml) {
      padding-right: $base-padding-x-l;
    }
  }
}

// Link
@mixin extended-clickarea($padding: 6, $vrt-only: true) {
  content: '';
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  top: rem(-$padding);

  @if $vrt-only {
    left: 0;
    padding: rem($padding) 0;
  } @else {
    left: rem(-$padding);
    padding: rem($padding);
  }
}

// p3 color
@mixin p3-color($property, $color) {
  $red: red($color) / 256;
  $green: green($color) / 256;
  $blue: blue($color) / 256;

  #{$property}: color(display-p3 $red $green $blue);

  @supports not (color: color(display-p3 1 1 1)) {
    #{$property}: $color;
  }
}

// Soft gradient
@function soft-gradient($color) {
  $coords: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0
  );

  $stops: null;

  @each $colorStop, $alphaValue in $coords {
    $stop: rgba($color, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  @return $stops;
}

// Rem + em
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function rem($values, $round: true) {
  $result: ();

  @each $value in $values {
    $val: strip-unit($value);
    $val: if($round, round($val), $val);
    $rem: if($val == 0, 0, $val / 16 * 1rem);
    $result: append($result, $rem, space);
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function em($values, $round: true) {
  $result: ();

  @each $value in $values {
    $val: strip-unit($value);
    $val: if($round, round($val), $val);
    $em: if($val == 0, 0, $val / 16 * 1em);
    $result: append($result, $em, space);
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

// Polyfluid sizing
@function list-sort($list) {
  $sortedlist: ();
  @while length($list) > 0 {
    $value: nth($list, 1);
    @each $item in $list {
      @if $item < $value {
        $value: $item;
      }
    }
    $sortedlist: append($sortedlist, $value, 'space');
    $list: list-remove($list, index($list, $value));
  }
  @return $sortedlist;
}

@function list-remove($list, $index) {
  $newList: ();
  @for $i from 1 through length($list) {
    @if $i != $index {
      $newList: append($newList, nth($list, $i), 'space');
    }
  }
  @return $newList;
}

@function map-sort($map) {
  $keys: list-sort(map-keys($map));
  $sortedMap: ();
  @each $key in $keys {
    $sortedMap: map-merge(
      $sortedMap,
      (
        $key: map-get($map, $key)
      )
    );
  }
  @return $sortedMap;
}

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@function linear-interpolation($map) {
  $keys: map-keys($map);
  // The slope
  $m1: strip-units(map-get($map, nth($keys, 2)) - map-get($map, nth($keys, 1)));
  $m2: strip-units(nth($keys, 2) - nth($keys, 1));
  $m: $m1 / $m2;

  // The y-intercept
  $b1: map-get($map, nth($keys, 1));
  $b2: strip-units($m * nth($keys, 1));
  $b: $b1 - $b2;

  // Determine if the sign should be positive or negative
  $sign: '+';
  @if ($b < 0) {
    $sign: '-';
    $b: abs($b);
  }

  @return calc(#{$m * 100}vw #{$sign} #{$b});
}

// @include poly-fluid-sizing('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
@mixin poly-fluid-sizing($property, $map) {
  // Get the number of provided breakpoints
  $length: length(map-keys($map));

  // Sort the map by viewport width (key)
  $map: map-sort($map);
  $keys: map-keys($map);

  // Minimum size
  #{$property}: map-get($map, nth($keys, 1));

  // Interpolated size through breakpoints
  @for $i from 1 through ($length - 1) {
    @media (min-width: nth($keys,$i)) {
      $value1: map-get($map, nth($keys, $i));
      $value2: map-get($map, nth($keys, ($i + 1)));
      // If values are not equal, perform linear interpolation
      @if ($value1 != $value2) {
        #{$property}: linear-interpolation(
          (
            nth($keys, $i): $value1,
            nth($keys, ($i + 1)): $value2
          )
        );
      } @else {
        #{$property}: $value1;
      }
    }
  }

  // Maxmimum size
  @media (min-width: nth($keys,$length)) {
    #{$property}: map-get($map, nth($keys, $length));
  }
}
