.o-map {
  width: 100%;
  height: rem(300);
}

.o-map__wrapper {
  height: 100%;
  background-color: $color-gray-bg;
}

.o-map__marker {
  width: 34px;
  height: 52px;
  display: none;

  // Only show if Mapbox has initialized the marker
  &[style] {
    display: block;
  }
}
