.l-offers {
  @media ($max-m) {
    > * + * {
      margin-top: 2rem;
    }
  }

  @media ($min-m) {
    display: flex;
    justify-content: space-between;
  }
}

.l-offers__item {
  position: relative;
  background-color: $color-green;
  color: $color-white;
  padding: 3rem $base-padding-x-s 3rem;

  @media ($min-s) {
    padding: 3.5rem 1.75rem 3.25rem;
  }

  @media ($min-ml) {
    padding: 4rem $base-padding-x-m 3.75rem;
  }

  @media ($max-ms) {
    & + & {
      margin-top: 1rem;
    }
  }

  // If only 1 item
  .l-offers--1 & {
    width: 100%;

    @media ($min-m) {
      padding: 4rem $base-padding-x-m 3.75rem;
    }

    @media ($min-l) {
      padding: 4rem $base-padding-x-l 3.75rem;
    }

    @media ($max-m) {
      > * + * {
        margin-top: 3.5rem;
      }
    }

    @media ($min-m) {
      display: flex;
      justify-content: space-between;

      > *:first-child {
        flex: 1 1 auto;
        max-width: $max-width-article;
      }

      > *:last-child {
        flex: 0 0 rem(300);
        margin-left: 7rem;
      }
    }
  }

  // 2 items
  .l-offers--2 & {
    @media ($min-m) {
      width: 48%;
      width: calc(50% - 0.25rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    > * + * {
      margin-top: 3.5rem;
    }
  }

  // 3 items
  .l-offers--3 & {
    @media ($min-m) {
      width: 30%;
      width: calc((100% / 3) - 0.25rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    > * + * {
      margin-top: 3.5rem;
    }
  }

  // Notch
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1rem 1.75rem 0 1.75rem;
    border-color: $color-gray-bg transparent transparent transparent;
  }

  // Headline + text
  > div:nth-child(1) {
    > * + * {
      margin-top: 0.75rem;
    }
  }

  // Contact
  > div:nth-child(2) {
    color: $color-black;

    > h4 {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: 85%;
      margin-bottom: 0.75rem;
    }

    > p {
      line-height: 1.4;

      span {
        display: block;

        &:first-child {
          font-weight: bold;
        }
      }

      & + p {
        margin-top: 1.125rem;
      }
    }
  }

  a {
    transition: color 150ms $ease-fade;

    &:hover,
    &:focus {
      color: $color-white;
    }
  }

  abbr {
    display: inline-block;
    width: 0.75em;
    border-bottom: none;
    text-decoration: none;
  }
}
