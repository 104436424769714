.l-cases-teaser {
  position: relative;
}

.l-cases-teaser__text {
  margin-top: 1.25rem;

  @media ($min-ms) {
    width: 50%;
    margin-top: 1.5rem;
  }
}

.l-cases-teaser__hl {
  @media ($min-ms) {
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 4rem;
    padding-left: $base-padding-x-m;
  }

  @media ($min-ml) {
    padding-top: 5rem;
    padding-left: $base-padding-x-l;
  }

  a {
    transition: color 150ms $ease-fade;

    &:hover,
    &:focus {
      color: $color-green;
    }
  }
}
