.o-skip-link {
  @extend .u-a11y-hidden;

  &:focus {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    clip: auto;
    padding: 0.625rem 1.25rem;
    z-index: 10;
    background-color: $color-gray-bg;
    text-decoration: none;
  }
}
