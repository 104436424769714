.l-about-hero {
}

.l-about-hero__wrapper {
  position: relative;
}

.l-about-hero__image {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    background: linear-gradient(to top, soft-gradient($color-gray-bg));

    @media ($min-ms) {
      height: 7rem;
    }
  }
}

.l-about-hero__overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $color-white;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;

  @media ($min-ms) {
    padding-bottom: 10rem;
  }
}

.l-about-hero__numbers {
  flex: 1;

  > * {
    position: sticky;
    top: 4rem;

    @media ($min-ms) {
      top: 6rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}

.l-about-hero__number {
  @media ($max-ms) {
    display: flex;
    align-items: center;

    & + & {
      margin-top: 1.5rem;
    }
  }

  @media ($min-ms) {
    text-align: center;
    width: 30%;
  }

  // number + unit
  > span:nth-child(1) {
    display: block;

    @media ($max-ms) {
      flex: 0 0 30%;
    }

    @media ($max-s) {
      flex: 0 0 6.625rem;
    }

    // number
    span:nth-child(1) {
      display: inline-block;
      line-height: 1;

      @include poly-fluid-sizing(
        'font-size',
        (
          $s: 3rem,
          $ml: 10rem
        )
      );
    }

    // unit
    span:nth-child(2) {
      display: inline-block;
      margin-left: 0.25rem;
      line-height: 1;
      max-width: 4ch;

      @include poly-fluid-sizing(
        'font-size',
        (
          $s: 1em,
          $ml: 2em
        )
      );
    }
  }

  // category
  > span:nth-child(2) {
    display: block;

    @media ($max-ms) {
      margin-left: 1.5rem;
      line-height: $line-height-medium;
    }

    @media ($min-ms) {
      margin-top: 1rem;
    }
  }
}

.l-about-hero__article {
  text-align: center;

  @media ($max-ms) {
    margin-top: 2rem;
  }
  @media ($min-ms) {
    margin-top: -3rem;
  }
}
