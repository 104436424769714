.l-image-teaser {
  position: relative;
}

.l-image-teaser__overlay {
  @media ($max-ms) {
    padding-top: 2.5rem;
    padding-bottom: 3rem;
    background-color: $color-white;
  }

  @media ($min-ms) {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 57%;
    height: 100%;
    padding-top: 2.5rem;

    &--right {
      right: 0;
      left: auto;
      width: 53%;
    }
  }

  @media ($min-m) {
    padding-top: 4.5rem;
  }

  &--white {
    @media ($min-ms) {
      color: $color-white;

      a,
      span {
        color: $color-white;

        &:hover,
        &:focus {
          color: $color-black;
        }
      }
    }
  }

  > div {
    position: relative;

    &:hover,
    &:focus-within {
      .l-image-teaser__more {
        color: $color-black;
      }
    }
  }
}

.l-image-teaser__hl {
  a {
    transition: color 150ms $ease-fade;

    &:hover,
    &:focus {
      color: $color-green;

      .l-image-teaser__overlay--white & {
        @media ($min-ms) {
          color: $color-black;
        }
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.l-image-teaser__more {
  &::after {
    content: none;
  }
}
