.o-radio {
  position: relative;
}

.o-radio__input {
  width: 1.25rem; // required info bubble is still visible at correct position
  height: 1.25rem;
  position: absolute;
  left: 0;
  top: em(2);
  opacity: 0;
  pointer-events: none;
}

.o-radio__label {
  user-select: none;
  border: 2px solid $color-gray-medium;
  padding: em(3 24 3);
  border-radius: 1px;
  display: block;
  text-align: center;
  transition: transform 300ms $ease-out, background-color 150ms ease-out,
    color 150ms ease-out, border-color 150ms ease-out;

  &:hover,
  &:focus {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
  }

  .o-radio__input:checked + & {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
  }
}
