.c-magazine-teaser {
  position: relative;

  &:hover,
  &:focus-within {
    .c-magazine-teaser__more {
      color: $color-black;
    }
  }
}

.c-magazine-teaser__hl {
  a {
    transition: color 150ms $ease-fade;

    &:hover,
    &:focus {
      color: $color-green;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.c-magazine-teaser__more {
  &::after {
    content: none;
  }
}
