.l-group {
}

.l-group__text {
  @media ($min-ms) {
    width: 50%;
  }
}

.l-group__logo {
  margin-top: 6rem;
  position: relative;

  @media ($min-ms) {
    margin-top: 5rem;
  }

  > * {
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
    width: rem(255 * 0.9);
    height: rem(35 * 0.9);

    @media ($min-ms) {
      width: rem(255 * 1.25);
      height: rem(35 * 1.25);
    }

    svg {
      fill: $color-green;
      display: block;
      width: 100%;
      height: 100%;
    }

    path:nth-of-type(2) {
      fill: $color-gray-medium;
    }
  }
}

.l-group__wrapper {
  border-top: 2px solid $color-black;
  margin-top: 3rem;

  @media ($min-ms) {
    display: flex;
    justify-content: space-between;
  }
}

.l-group__column {
  @media ($max-ms) {
    & + & {
      margin-top: 6rem;
    }
  }
  @media ($min-ms) {
    width: 46%;
  }
}

.l-group__segment {
  text-align: center;
  padding-bottom: 2.75rem;
  margin-top: 2rem;
  border-bottom: 1px solid $color-black;

  > * + * {
    margin-top: 0.5rem;
  }

  figure {
    margin-top: 1rem;
    max-width: rem(56);
    margin-left: auto;
    margin-right: auto;
  }
}

.l-group__item {
  margin-top: 4rem;

  figure {
    max-width: rem(170);
  }
}
