.l-services-teaser {
  > * + * {
    margin-top: 4rem;

    @media ($min-ms) {
      margin-top: 6rem;
    }
  }
}

.l-services-teaser__list {
  @media ($max-ms) {
    > * + * {
      margin-top: 6rem;
    }
  }
  @media ($min-ms) {
    display: flex;
    flex-wrap: wrap;

    > * {
      width: 29%;

      &:nth-child(3n + 2),
      &:nth-child(3n + 3) {
        margin-left: 6.5%;
      }

      &:nth-child(n + 4) {
        margin-top: 6rem;
      }
    }
  }
}

.l-services-teaser__item {
  position: relative;

  &:hover,
  &:focus-within {
    .l-services-teaser__icon {
      transform: scale(1.05);
    }

    .l-services-teaser__more {
      background-color: $color-black;
    }
  }
}

.l-services-teaser__icon {
  transition: transform 600ms $ease-out;
}

.l-services-teaser__hl {
  a {
    transition: color 150ms $ease-fade;

    &:hover,
    &:focus {
      color: $color-green;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.l-services-teaser__icon {
  max-width: rem(200);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
  display: block;
}
