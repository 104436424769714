.l-article {
  position: relative;
}

.l-article__content {
  max-width: $max-width-article;
  margin-left: auto;
  margin-right: auto;
}

.l-article__links {
  @media ($max-ml) {
    margin-top: 4rem;
    max-width: $max-width-article;
    margin-left: auto;
    margin-right: auto;
  }

  @media ($min-ml) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: rem(175);

    > * {
      position: sticky;
      top: 2.5em;
      left: 0;
    }
  }

  > * > * + * {
    margin-top: 1.75rem;
  }
}
