.l-home-hero {
  position: relative;
  overflow: hidden;
}

.l-home-hero__image {
  position: relative;

  > *:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
  }

  .l-home-hero--loop & {
    > *:nth-child(1) {
      opacity: 0;
      z-index: -1;
    }

    > *:nth-child(2) {
      opacity: 1;
      z-index: 1;
    }
  }
}

.l-home-hero__overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  color: $color-white;
  background: linear-gradient(140deg, $color-green 25%, $color-blue 150%);
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  display: flex;
  align-items: center;
  //min-width: 33%;

  @media ($min-ms) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  // Animation
  transform: translateX(-100%);
  transition: transform 1000ms $ease-out;

  .l-home-hero--anim &,
  .no-js & {
    transform: translateX(0%);
  }
}

.l-home-hero__hl {
  padding-right: 0.25rem;

  @media ($min-ms) {
    padding-right: 1rem;
  }

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-hl-1, 1) * 0.9,
      $ml: nth($font-size-hl-1, 2) * 1.25,
      $xl: nth($font-size-hl-1, 2) * 1.5
    )
  );

  // Lines
  > span {
    display: block;

    // Animation
    transform: translateX(-200%);
    transition: transform 1500ms $ease-out;

    @for $i from 0 through 3 {
      &:nth-child(#{$i + 1}) {
        transition-delay: $i * 200ms;

        > span {
          @for $j from 0 through 13 {
            &:nth-child(#{$j + 1}) {
              transition-delay: ($j * 60ms) + (400ms * $i) + 200ms;
            }
          }
        }
      }
    }

    .l-home-hero--anim &,
    .no-js & {
      transform: translateX(0%);
    }

    > span {
      opacity: 0.2;
      transition: opacity 500ms $ease-fade;

      .l-home-hero--anim &,
      .no-js & {
        opacity: 1;
      }
    }
  }
}
