.c-form {
  position: relative;

  noscript p {
    padding: 1rem 1.25rem;
    display: block;
    margin-bottom: 3rem;
    border: 3px solid $color-red;
  }
}

.c-form__form {
  .c-form--success & {
    visibility: hidden;
  }
}

.c-form__row {
  & + & {
    margin-top: 1.125rem;
  }

  & + &--checkbox,
  & + &--radio {
    margin-top: 1.75rem;
  }

  &--checkbox + &--checkbox {
    margin-top: 2.5rem;
  }

  &--radio + &--checkbox {
    margin-top: 3rem;
  }

  &--columns {
    @media ($max-ms) {
      > * + * {
        margin-top: 1rem;
      }
    }
    @media ($min-ms) {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;

      > * {
        width: 48.5%;
      }
    }
  }
}

.c-form__button {
  margin-top: 1.875rem;
}

.c-form__contact {
  margin-top: 1.875rem;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-form-small, 1),
      $ml: nth($font-size-form-small, 2)
    )
  );

  > * + * {
    margin-top: 0.25em;
  }
}

.c-form__error {
  color: $color-red;
  position: relative;
  padding-left: 1.625em;
  margin-top: 1.5rem;
  display: none;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-form-small, 1),
      $ml: nth($font-size-form-small, 2)
    )
  );

  .c-form--error & {
    display: block;
  }

  span {
    background-color: $color-red;
    position: absolute;
    left: 0;
    top: em(3);
    width: 1.125em;
    height: 1.125em;
    text-align: center;
    border-radius: 50%;
    color: $color-white;
    font-weight: bold;
    line-height: 1.25em;
    font-size: 1em;
  }
}

.c-form__success {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  &--contact {
    background-color: $color-gray-bg;
  }

  &--event {
    background-color: $color-gray-bg;
    text-align: center;
  }

  &--newsletter {
    background-color: $color-white;
    text-align: center;
  }

  visibility: hidden;

  .c-form--success & {
    visibility: visible;
  }
}

.c-form__error,
.c-form__success {
  .js-focus-visible &:focus:not(.focus-visible) {
    outline: none;
  }
}

// .c-form__footer {
//   margin-top: 1.75rem;
//   font-size: em(12);
//   color: $color-gray-medium;

//   // overwrite o-text-link
//   a {
//     text-decoration-color: currentColor;
//   }
// }
