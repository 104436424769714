.o-category {
  &--gray {
    color: $color-gray-dark;
  }

  &--green {
    color: $color-green;
  }

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-category, 1),
      $ml: nth($font-size-category, 2)
    )
  );
}

.o-category__separator {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin-left: em(4);
  margin-right: em(5);
  vertical-align: middle;
  margin-bottom: em(2);
  background-color: currentColor;
}
