.c-about-numbers {
  color: $color-white;
  position: relative;

  @media ($max-ms) {
    > * + * {
      margin-top: 2rem;
    }
  }

  @media ($min-ms) {
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
}

.c-about-numbers__number {
  @media ($max-ms) {
    display: flex;
    align-items: flex-end;
  }

  @media ($min-ms) {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  // number + unit
  > span:nth-child(1) {
    display: block;

    @media ($max-ms) {
      flex-shrink: 0;
    }

    // number
    span:nth-child(1) {
      display: inline-block;
      line-height: 1;

      @include poly-fluid-sizing(
        'font-size',
        (
          $s: 2.5rem,
          $ml: 6.5rem
        )
      );
    }

    // unit
    span:nth-child(2) {
    }
  }

  // category
  > span:nth-child(2) {
    display: block;

    @media ($max-ms) {
      margin-left: 1.5rem;
      margin-bottom: 0.125rem;
      line-height: $line-height-medium;
    }

    @media ($min-ms) {
      margin-top: 1rem;
    }
  }

  .o-citation button {
    &:hover,
    &:focus {
      color: $color-black;
    }
  }
}
