.o-checkbox {
  position: relative;
}

.o-checkbox__input {
  width: 1.25rem; // required info bubble is still visible at correct position
  height: 1.25rem;
  position: absolute;
  left: 0;
  top: em(2);
  opacity: 0;
  pointer-events: none;
}

.o-checkbox__label {
  display: inline-flex;
  align-items: flex-start;
  user-select: none;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-form-small, 1),
      $ml: nth($font-size-form-small, 2)
    )
  );
}

.o-checkbox__label::before {
  content: '';
  display: inline-block;
  flex-shrink: 0;
  width: em(21);
  height: em(20);
  position: relative;
  top: em(1);
  margin-right: 0.625em;
  background-color: $color-white;
  border: 1px solid rgba($color-gray-medium, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 77.5%;
  outline-offset: -2px;
  outline: 2px solid transparent;
  transition: transform 300ms $ease-out, outline 150ms $ease-fade;

  .o-checkbox--light & {
    background-color: lighten($color-gray-bg, 3);
  }

  .o-checkbox__input:focus + & {
    outline-color: $color-green;
  }

  .o-checkbox__input:active + & {
    transform: scale(0.8);
  }

  .o-checkbox__input:checked:active + & {
    transform: none;
    transition: none;
  }

  .o-checkbox__input:checked + & {
    background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='rgb(49, 49, 48)' stroke-width='4' d='M1.7 11.5l7.6 7.7L22.6 4.8'/%3E%3C/svg%3E");
  }
}
