$font-url: '../fnt/gt-walsheim-pro-';
$font-family: 'GT Walsheim Web';

@font-face {
  font-family: $font-family;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url($font-url+'regular.woff2') format('woff2'),
    url($font-url+'regular.woff') format('woff');
}

@font-face {
  font-family: $font-family;
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url($font-url+'regular-oblique.woff2') format('woff2'),
    url($font-url+'regular-oblique.woff') format('woff');
}

@font-face {
  font-family: $font-family;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url($font-url+'bold.woff2') format('woff2'),
    url($font-url+'bold.woff') format('woff');
}

@font-face {
  font-family: $font-family;
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url($font-url+'bold-oblique.woff2') format('woff2'),
    url($font-url+'bold-oblique.woff') format('woff');
}
