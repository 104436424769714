.l-management {
  @media ($min-ms) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.l-management__item {
  // margins
  @media ($max-ms) {
    & + & {
      margin-top: 4rem;
    }
  }

  @media ($min-ms) {
    &:nth-child(n + 4) {
      margin-top: 6rem;
    }
  }

  // widths
  @media ($min-ms) {
    width: 47%;
    width: calc((100% / 3) - #{$base-padding-x-m * 0.75});
  }

  @media ($min-ml) {
    width: calc((100% / 3) - #{$base-padding-x-l * 0.75});
  }
}
