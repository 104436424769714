.l-event-hero {
  position: relative;
}

.l-event-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.l-event-hero__hl {
  text-align: center;
  padding-top: 1rem;

  span {
    display: block;

    &:nth-child(1) {
      line-height: 1;

      @include poly-fluid-sizing(
        'font-size',
        (
          $s: nth($font-size-hl-1, 1) * 1.25,
          $ml: nth($font-size-hl-1, 2) * 1.75
        )
      );
    }

    &:nth-child(2) {
      margin-top: 1em;
    }
  }
}
