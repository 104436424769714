.l-contact {
  @media ($min-m) {
    display: flex;
    justify-content: space-between;
  }
}

.l-contact__column {
  @media ($max-m) {
    & + & {
      margin-top: 5rem;
    }
  }

  @media ($min-m) {
    width: 29%;
    width: calc((100% - (2 * #{$base-padding-x-l}) - 1px) / 3);
  }
}

.l-contact__icon {
  width: 1.875rem;
  height: 1.875rem;

  svg {
    width: 100%;
    height: 100%;
    fill: $color-green;
  }
}

.l-contact__hl {
  margin-top: 0.75rem;
  margin-bottom: 1.75rem;
}

.l-contact__address {
  margin-top: -0.5em;
  margin-bottom: 1.5rem;
}

.l-contact__map {
  margin-top: 2.375rem;
}
