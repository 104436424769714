.l-news {
  @media ($min-ms) {
    display: flex;
  }
}

.l-news__item {
  background-color: $color-white;

  @media ($min-ms) {
    width: 50%;
  }

  &:nth-child(even) {
    position: relative;

    &::before {
      content: '';
      background-color: $color-gray-bg;
      position: absolute;
      height: 2px;
      top: -1px;
      width: calc(100% - #{$base-padding-x-s * 2});
      left: $base-padding-x-s;

      @media ($min-s) {
        width: calc(100% - #{$base-padding-x-m * 2});
        left: $base-padding-x-m;
      }

      @media ($min-ms) {
        width: 2px;
        height: 80%;
        top: 10%;
        left: -1px;
      }
    }
  }
}
