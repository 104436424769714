.l-columns {
  @media ($min-ms) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.l-columns__item {
  // margins
  @media ($max-ms) {
    & + & {
      margin-top: 5rem;

      .l-columns--close & {
        margin-top: 3rem;
      }
    }
  }

  @media ($min-ms) {
    &:nth-child(n + 3) {
      margin-top: 7rem;

      .l-columns--close & {
        margin-top: 5rem;
      }
    }
  }

  // widths
  @media ($min-ms) {
    width: 47%;
    width: calc(50% - #{$base-padding-x-m * 0.75});
  }

  @media ($min-ml) {
    width: calc(50% - #{$base-padding-x-l * 0.75});
  }
}
