.l-services-hero {
  position: relative;
}

.l-services-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &--right {
    display: block;
    left: auto;
    right: 0;
    padding-top: 2rem;

    @media ($min-ms) {
      padding-top: 4.5rem;
      width: 60%;
      text-align: right;
    }
  }

  &--white {
    color: $color-white;
  }
}

.l-services-hero__hl {
  @media ($max-ms) {
    br {
      display: none;
    }
  }

  span {
    display: block;
  }

  &--large {
    @include poly-fluid-sizing(
      'font-size',
      (
        $s: nth($font-size-hl-1, 1) * 1.1,
        $ml: nth($font-size-hl-1, 2) * 1.75
      )
    );
  }
}
