.c-text-teaser {
  background-color: $color-white;
  color: $color-green;
  max-width: rem(600);
  padding-top: 2rem;
  padding-bottom: 2.375rem;
  padding-right: $base-padding-x-s;
  padding-left: $base-padding-x-s;

  @media ($min-s) {
    padding-right: $base-padding-x-m;
    padding-left: $base-padding-x-m;
  }

  @media ($min-ml) {
    padding-right: $base-padding-x-l;
    padding-left: $base-padding-x-l;
  }

  &--article {
    max-width: none;

    @media ($min-ml) {
      padding-right: $base-padding-x-m;
      padding-left: $base-padding-x-m;
    }
  }
}
