.l-quote {
  position: relative;
}

.l-quote__overlay {
  @media ($max-ms) {
    padding-top: 1.75rem;
    padding-bottom: 2rem;
    background-color: $color-white;
  }
  @media ($min-ms) {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 3rem;
  }
}
