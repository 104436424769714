.c-nav__toggle {
  display: block;
  box-sizing: content-box;
  width: rem(32);
  height: rem(32);
  padding: 0.375rem;
  position: relative;
  z-index: 10;
  right: -0.625rem;

  @media ($min-m) {
    display: none;
  }
}

.c-nav__wrapper {
  position: relative;

  @media ($max-s) {
    padding-left: $base-padding-x-s;
    padding-right: $base-padding-x-s;
  }

  @media ($min-s) and ($max-m) {
    padding-left: $base-padding-x-m;
    padding-right: $base-padding-x-m;
  }

  @media ($max-m) {
    padding-top: 7rem;
    padding-bottom: 3rem;
    position: absolute;
    z-index: 8;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    background-color: $color-white;
    box-shadow: $box-shadow-default;
  }
}

.c-nav__list {
  @media ($max-m) {
    font-size: rem(24);
  }

  @media ($min-m) {
    margin-top: rem(4);
  }
}

.c-nav__sub-list {
  font-size: rem(20);
  padding-left: 1.25rem;
  padding-top: 0.125rem;
  padding-bottom: 0.625rem;

  @media ($min-m) {
    display: none;
  }
}

.c-nav__item {
  @media ($max-m) {
    margin-top: 0.5rem;
  }

  @media ($min-m) {
    display: inline-block;
    margin-left: 1.37rem;

    &--hidden {
      display: none;
    }
  }
}

.c-nav__sub-item {
  @media ($max-m) {
    margin-top: 0.5rem;
  }
}

// Animation
@media ($max-m) {
  .c-nav__wrapper {
    visibility: hidden; // A11Y: Prevent focus if nav is hidden
    opacity: 0;

    &--active {
      visibility: visible;
      opacity: 1;
    }

    // separate class to prevent resize flicker
    &--anim {
      transition: opacity 300ms $ease-fade, visibility 0ms linear 300ms;

      &.c-nav__wrapper--active {
        transition-delay: 0ms, 0ms;
      }
    }
  }

  .c-nav__item {
    transition: opacity 100ms $ease-fade, transform 500ms $ease-out;
    opacity: 0;
    transform: translateY(1em);

    .c-nav__wrapper--active & {
      transition: opacity 300ms $ease-fade, transform 600ms $ease-out;
      opacity: 1;
      transform: translateY(0);

      // delays
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 30ms;
        }
      }
    }
  }
}
