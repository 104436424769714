$header-height-small: 4.5rem;
$header-height-large: 6rem;

.l-header {
  &--fixed {
    &::after {
      content: '';
      display: block;
      height: $header-height-small;

      @media ($min-ms) {
        height: $header-height-large;
      }
    }
  }
}

.l-header__wrapper {
  @include base-padding-x;
  height: $header-height-small;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 6;

  @media ($min-ms) {
    height: $header-height-large;
  }

  .l-header--fixed & {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: $max-width-body;
  }

  // bg + shadow
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    z-index: 2;

    .l-header--fixed &,
    .l-header--contact-visible & {
      box-shadow: $box-shadow-default;
    }
  }
}

.l-header__logo {
  display: block;
  position: relative;
  z-index: 10;
  width: rem(180 * 0.8);
  height: rem(30 * 0.8);

  @media ($min-ms) {
    width: rem(180);
    height: rem(30);
  }

  .l-header--group & {
    width: rem(255 * 0.8);
    height: rem(35 * 0.8);

    @media ($min-ms) {
      width: rem(255);
      height: rem(35);
    }
  }

  svg {
    fill: $color-green;
    display: block;
    width: 100%;
    height: 100%;
  }

  path:nth-of-type(2) {
    fill: $color-gray-medium;
  }
}

.l-header__nav {
  z-index: 4;
  margin-left: auto;

  .l-header--group & {
    display: none;
  }
}

.l-header__quicknav {
  background-color: $color-white;
  box-shadow: $box-shadow-default;
  padding: 0.25rem 0.5rem;
  display: inline-flex;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;

  @media (min-width: $max-width-body) {
    right: calc((100% - #{$max-width-body}) / 2);
  }

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: rem(15),
      $ml: nth($font-size-default, 2)
    )
  );

  .l-header--group & {
    display: none;
  }

  // Animation
  visibility: hidden;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 100ms $ease-fade, visibility 0ms linear 100ms,
    transform 200ms $ease-in;

  .l-header--quicknav-visible & {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 200ms $ease-fade, visibility 0ms linear 0ms,
      transform 400ms $ease-out;
  }

  // do not show on contact page
  &--menu-only > *:nth-child(1) {
    display: none;
  }

  > *:nth-child(1) {
    margin-right: 0.375rem;
  }
}

.l-header__contact {
  @include base-padding-x;
  padding-top: 6rem;
  padding-bottom: 3rem;
  box-shadow: $box-shadow-default;
  background-color: $color-gray-bg;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;

  @media ($min-ms) {
    padding-top: 8rem;
  }

  .l-header--group & {
    display: none;
  }

  // Animation
  visibility: hidden;
  opacity: 0;
  height: 0; // no fade out transition, since height: 0; immediately
  min-height: auto;
  transform: translateY(3rem);
  transform-origin: top;
  transition: opacity 100ms $ease-fade, visibility 0ms linear 100ms,
    transform 200ms $ease-in;

  .l-header--contact-visible & {
    visibility: visible;
    opacity: 1;
    height: auto;
    min-height: 100vh;
    transform: translateY(0);
    transition: opacity 200ms $ease-fade, visibility 0ms linear 0ms,
      transform 400ms $ease-out;
  }
}

.l-header__contact-close {
  padding: 0.5em;
  margin-left: auto;
  width: 2.25em;
  height: 2.25em;
  display: block;
  position: relative;
  right: -0.5em;

  svg {
    fill: none;
    stroke: $color-black;
    stroke-width: 3.5;
    display: inline-block;
    width: 100%;
    height: 100%;
    display: block;
  }
}
