.o-fieldset {
}

.o-fieldset__legend {
  width: 100%;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-form-small, 1),
      $ml: nth($font-size-form-small, 2)
    )
  );
}

.o-fieldset__checkbox-list {
  margin-top: 1em;
  display: flex;

  > * + * {
    margin-left: 1.5em;
  }
}

.o-fieldset__radio-list {
  margin-top: 0.5em;
  display: flex;
  justify-content: space-between;

  @media ($min-ms) {
    width: 48.5%;
  }

  > * {
    width: 48%;
  }
}

.o-fieldset__alert {
  margin-top: 0.75em;
  color: $color-red;
  position: relative;
  padding-left: 1.625em;
  display: none;

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-form-small, 1),
      $ml: nth($font-size-form-small, 2)
    )
  );

  &--visible {
    display: block;
  }

  span {
    background-color: $color-red;
    position: absolute;
    left: 0;
    top: em(3);
    width: 1.125em;
    height: 1.125em;
    text-align: center;
    border-radius: 50%;
    color: $color-white;
    font-weight: bold;
    line-height: 1.25em;
    font-size: 1em;
  }
}
