.l-map-section {
  position: relative;
}

.l-map-section__overlay {
  @media ($min-ms) {
    &--white {
      color: $color-white;
    }
  }

  @media ($max-ms) {
    padding-top: 2.5rem;
    padding-bottom: 3rem;
    background-color: $color-white;
  }

  @media ($min-ms) {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 57%;
    height: 100%;
    padding-top: 2.5rem;
  }

  @media ($min-m) {
    padding-top: 4.5rem;
  }
}

.l-map-section__map {
  position: relative;

  @media ($min-ms) {
    width: 60%;
    margin-left: auto;
  }
}

.l-map-section__map-anim {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.l-map-section__legend {
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 0 10px rgba($color-black, 0.1);

  @media ($max-ms) {
    > * + * {
      margin-top: 2rem;
    }
  }

  @media ($min-ms) {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: space-between;

    > * {
      width: 30%;
    }
  }

  > div {
    padding-left: 2.5rem;
    position: relative;

    @media ($min-ms) {
      padding-left: 3rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: em(3);
      left: 0;
      width: 1.25em;
      height: 1.25em;
      border-radius: 50%;
    }

    &:nth-child(1)::before {
      background-color: $color-green;
    }

    &:nth-child(2)::before {
      background-color: #66e0ad;
    }

    &:nth-child(3)::before {
      background-color: #d0d0c9;
    }
  }
}
