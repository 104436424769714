.c-article-search {
}

.c-article-search__hl {
  margin-bottom: 0.75rem;
}

.c-article-search__button {
  margin-top: 1em;
}
