.c-popular-tags {
}

.c-popular-tags__hl {
  margin-bottom: 0.75rem;
}

.c-popular-tags__list {
  margin-top: -0.375em;

  li {
    display: inline-block;
    margin-right: 0.125em;
    margin-top: 0.375em;
  }
}
