.o-text-link {
  text-decoration: underline;
  text-decoration-color: $color-gray-dark;
  transition: color 150ms $ease-fade, text-decoration-color 150ms $ease-fade;

  &:hover,
  &:focus {
    color: $color-green;
    text-decoration-color: $color-green;
  }

  &:active {
    color: currentColor;
    text-decoration-color: currentColor;
  }
}
