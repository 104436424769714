::selection {
  background-color: $color-black;
  color: $color-white;
}

:focus {
  outline: 3px solid $color-blue;
  outline-offset: 1px;
}

html {
  background-color: $color-gray-bg;
  color: $color-black;
  font-family: $font-family-default;
  font-size: 1em;
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  font-variant: no-common-ligatures;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  // prevent long word wrap
  @media ($max-xs) {
    hyphens: auto;
  }
}

body {
  min-height: 100vh;
  overflow-y: scroll; // always show scrollbar
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-width-body;
  box-shadow: 0 0 50px rgba($color-black, 0.05);

  @include poly-fluid-sizing(
    'font-size',
    (
      $s: nth($font-size-default, 1),
      $ml: nth($font-size-default, 2)
    )
  );
}

main {
  flex-grow: 1;
}
