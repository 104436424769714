.o-quote {
  quotes: '\00BB''\00AB';

  > p::before {
    content: open-quote;
  }

  > p::after {
    content: close-quote;
  }

  > p {
    line-height: $line-height-medium;
    color: $color-green;

    @include poly-fluid-sizing(
      'font-size',
      (
        $s: nth($font-size-quote, 1),
        $ml: nth($font-size-quote, 2)
      )
    );
  }

  &--large > p {
    line-height: $line-height-narrow;

    @include poly-fluid-sizing(
      'font-size',
      (
        $s: nth($font-size-quote-large, 1),
        $ml: nth($font-size-quote-large, 2)
      )
    );
  }
}

.o-quote__footer {
  padding-top: 1.5rem;
  color: $color-black;
}
