// Colors
$color-black: rgb(49, 49, 48);
$color-white: rgb(255, 255, 255);
$color-green: rgb(0, 203, 118);
$color-blue: rgb(0, 144, 225);
$color-red: rgb(222, 89, 89);
$color-gray-bg: rgb(243, 243, 240);
$color-gray-medium: rgb(168, 168, 168);
$color-gray-dark: rgb(140, 140, 140);

// Breakpoints
$xs: em(360);
$s: em(480);
$ms: em(720);
$m: em(960);
$ml: em(1200);
$l: em(1440);
$xl: em(1680);
$xxl: em(1920);

$max-xs: 'max-width: #{$xs - (1/16)}';
$min-xs: 'min-width: #{$xs}';
$max-s: 'max-width: #{$s - (1/16)}';
$min-s: 'min-width: #{$s}';
$max-ms: 'max-width: #{$ms - (1/16)}';
$min-ms: 'min-width: #{$ms}';
$max-m: 'max-width: #{$m - (1/16)}';
$min-m: 'min-width: #{$m}';
$max-ml: 'max-width: #{$ml - (1/16)}';
$min-ml: 'min-width: #{$ml}';
$max-l: 'max-width: #{$l - (1/16)}';
$min-l: 'min-width: #{$l}';
$max-xl: 'max-width: #{$xl - (1/16)}';
$min-xl: 'min-width: #{$xl}';
$max-xxl: 'max-width: #{$xxl - (1/16)}';
$min-xxl: 'min-width: #{$xxl}';

// Fonts
$font-family-default: 'GT Walsheim Web', -system-ui, -apple-system,
  BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

// Font sizes
$font-size-default: (rem(17), rem(19));
$font-size-category: (rem(15), rem(17));
$font-size-hl-1: (rem(28), rem(36));
$font-size-hl-2: (rem(22), rem(28));
$font-size-quote: (rem(19), rem(22));
$font-size-quote-large: (rem(22), rem(36));
$font-size-form-small: (rem(16), rem(17));

// Line heights
$line-height-default: 1.6;
$line-height-medium: 1.4;
$line-height-narrow: 1.3;

// Letter spacings
$letter-spacing-default: 0.02em;
// $letter-spacing-medium: 0.0375em;

// Paddings
$base-padding-x-s: 1rem;
$base-padding-x-m: 2.5rem;
$base-padding-x-l: 4.25rem;

// Max widths
$max-width-body: rem(1600);
$max-width-article: rem(680);

// Easings
$ease-out: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in: cubic-bezier(0, 0, 0.2, 1);
$ease-in-out: cubic-bezier(0.55, 0, 0.1, 1);
$ease-fade: cubic-bezier(0, 0, 0.25, 1);

// Shadows
$box-shadow-default: 0 2px 5px rgba($color-black, 0.2);
