.c-event-activity {
  position: relative;
  padding-right: 2.25rem;

  & + & {
    margin-top: 2rem;
  }

  @media ($min-m) {
    width: 50%;

    &:nth-child(even) {
      margin-left: auto;
      padding-right: 0;
      padding-left: 2.25rem;
    }

    & + & {
      margin-top: -2.75rem;
    }
  }
}

.c-event-activity__content {
  background-color: $color-white;
  padding: 1.25rem 1.25rem 1.275rem;
  position: relative;

  @media ($min-s) {
    padding: 1.75rem 2rem 1.75rem;
  }

  .c-event-activity--dark & {
    background-color: $color-black;
    color: $color-white;

    &::after {
      background: $color-black;
    }
  }

  // triangle
  &::after {
    content: '';
    position: absolute;
    top: 2rem;
    right: -0.5rem;
    width: 0;
    height: 0;
    padding: 0.5rem;
    background: $color-white;
    border: inherit;
    border-right: 0;
    border-bottom: 0;
    transform: rotate(45deg);

    @media ($min-m) {
      .c-event-activity:nth-child(even) & {
        right: auto;
        left: -0.5rem;
      }
    }
  }
}

.c-event-activity__time {
  margin-bottom: 0.625rem;
}

// dotted border
.c-event-activity__border {
  background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 20'%3E%3Ccircle fill='rgb(0, 203, 118)' cx='5' cy='10' r='4.5'/%3E%3C/svg%3E");
  background-repeat: space;
  position: absolute;
  top: 55px;
  bottom: -56px;
  right: 0;
  width: 10px;

  .c-event-activity:last-child & {
    bottom: 0;
  }

  @media ($min-m) {
    right: -5px;
    bottom: 19px;

    .c-event-activity:nth-child(even) & {
      left: -5px;
      right: auto;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background-color: $color-black;
    border-radius: 50%;
    position: absolute;
    top: -22px;
    left: -2px;
    z-index: 2;
  }

  .c-event-activity:last-child &:after {
    content: '';
    display: block;
    height: 90px;
    width: 10px;
    bottom: 0;
    position: absolute;
    background: linear-gradient(
      to top,
      $color-gray-bg 0%,
      $color-gray-bg 25%,
      rgba($color-gray-bg, 0.75) 25%,
      rgba($color-gray-bg, 0.75) 50%,
      rgba($color-gray-bg, 0.5) 50%,
      rgba($color-gray-bg, 0.5) 75%,
      rgba($color-gray-bg, 0.25) 75%,
      rgba($color-gray-bg, 0.25) 100%
    );
  }
}
