.o-tag {
  display: inline-block;
  background-color: rgba($color-gray-medium, 0.3);
  padding: em(3 11 2);
  color: $color-black;
  border-radius: 1px;
  transition: transform 300ms $ease-out, color 150ms $ease-fade,
    background-color 150ms $ease-fade;

  &:hover,
  &:focus {
    color: $color-white;
    background-color: $color-green;
  }

  &:active {
    transform: scale(0.95);
  }
}
