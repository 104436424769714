.o-marker-icon {
  width: 100%;
  height: 100%;
  display: block;

  path {
    fill: $color-green;
  }

  &--blue path {
    fill: $color-blue;
  }

  circle {
    fill: white;
  }

  ellipse {
    fill: $color-black;
    opacity: 0.2;
  }
}
