.l-event-location__map {
  width: 100%;
  height: 50vw;
  max-height: rem(500);
  min-height: rem(300);
}

.l-event-location__list {
  padding-top: 1.5rem;
  max-width: 900/16 * 1rem;
  margin-left: auto;
  margin-right: auto;

  @media ($min-m) {
    padding-top: 3rem;
  }

  @media ($min-ms) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.l-event-location__item {
  background-color: $color-white;
  padding: 1.5rem 1.25rem 1.275rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: flex-start;

  @media ($min-s) {
    padding: 1.875rem 2rem;
  }

  @media ($min-m) {
    width: 48%;
  }

  // icon
  > *:nth-child(1) {
    flex: 0 0 2.5rem;
    margin-right: 1.75rem;

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  // text spacing
  > *:nth-child(2) > * + * {
    margin-top: 0.875rem;
  }
}


